import { css } from '@emotion/react';
import styled from '@emotion/styled';


export const StyledAppAmount = styled.span(
  ({ theme }) => css`
    display: inline-flex;
    justify-content: start;
    margin: 0;
  `
);

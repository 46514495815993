import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SwipeableDrawer } from '@mui/material';

import { useScrollbarStyles } from 'hooks/useScrollbarStyles';

import { IAppDrawerProps } from './AppDrawer';

export const StyledAppDrawer = styled(SwipeableDrawer)<
  Pick<IAppDrawerProps, 'anchor'>
>(
  ({ theme, anchor }) => css`
    .MuiModal-backdrop {
      background-color: ${theme.palette.background.overlay};
    }
    .MuiDrawer-paper {
      padding: 16px 16px 30px;
      display: grid;
      box-shadow: ${theme.shadows[2]};
      border-radius: ${anchor &&
      {
        left: '0',
        right: '0',
        top: '0',
        bottom: '6px 6px 0 0',
      }[anchor]};
      max-height: calc(100vh - 40px);
      max-height: calc(100dvh - 40px);
      transition: height ${theme.transitions.duration.shortest}ms ease-out;

      ${useScrollbarStyles()}
    }
  `,
);

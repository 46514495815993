import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledDatePicker = styled.div<{
  weeksInMonth: number;
}>(({ theme, weeksInMonth }) => {
  const rowHeight = 46;
  const minHeight = 114;
  const calendarHeight = weeksInMonth * rowHeight + minHeight;

  return css`
    padding: 0 12px 16px;
    border-radius: 10px;
    background: ${theme.palette.background.default};
    counter-reset: index;

    box-shadow: ${theme.shadows[24]};

    @media (max-width: ${theme.breakpoints.values.xxs}px) {
      padding: 0 12px 16px;
    }

    .caption__wrapper {
      display: grid;
      grid-auto-flow: column;
      gap: 6px;
      cursor: pointer;
    }

    .caption__icon {
      padding: 2px;
      color: ${theme.palette.primary.main};
      transform: rotate3d(180, 0, 1, 0deg);
      transition: transform 0.3s ease;
    }

    .caption__icon_open {
      transform: rotate3d(180, 0, 1, 180deg);
      transition: transform 0.3s ease;
    }

    &.rdp-collapse {
      .rdp-row {
        transform: translateY(0);
        opacity: 1;
      }

      .rdp-row-inactive {
        display: none;

        transform: translateY(-100%);
        opacity: 0;
      }
    }

    .rdp-head {
      position: relative;
      background: ${theme.palette.background.default};
      z-index: 10;
    }

    .rdp-tbody {
      position: relative;
    }

    .rdp-month {
      overflow: hidden;
    }

    .rdp-row:nth-of-type(1) {
      --index: 0;
    }
    .rdp-row:nth-of-type(2) {
      --index: 1;
    }
    .rdp-row:nth-of-type(3) {
      --index: 2;
    }
    .rdp-row:nth-of-type(4) {
      --index: 3;
    }
    .rdp-row:nth-of-type(5) {
      --index: 4;
    }
    .rdp-row:nth-of-type(6) {
      --index: 5;
    }

    .rdp-row:not(.rdp-row-inactive) {
      z-index: 10;
      background-color: ${theme.palette.background.default};
    }

    .rdp-table {
      width: 100%;

      th,
      td {
        width: 77px;
        height: 38px;
        text-align: center;
        padding: 4px 0;
      }

      th {
        text-transform: capitalize;
        color: ${theme.palette.text.secondary};
        font-size: 14px;
        font-weight: 600;
      }

      td {
        button {
          font-family: inherit;
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          border: none;
          background-color: transparent;
          border-radius: 4px;
          width: 38px;
          height: 38px;

          &:disabled {
            color: ${theme.palette.text.disabled};
          }

          &:not(:disabled) {
            cursor: pointer;
            color: ${theme.palette.text.secondary};
          }

          &.rdp-day_today {
            color: ${theme.palette.primary.main};
            font-weight: 600;
            background-color: ${theme.palette.controls.light};
          }

          &.rdp-day_selected {
            background-color: ${theme.palette.primary.main};
            color: ${theme.palette.text.invertedPrimary};
          }

          &.rdp-day_outside {
            color: ${theme.palette.text.disabled};
          }
        }
      }

      @media (max-width: ${theme.breakpoints.values.xxs}px) {
        td button {
          width: 34px;
          height: 34px;
        }
      }
    }
  `;
});

export const StyledCaption = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;
    margin: 0 -12px;
    border-bottom: 1px solid ${theme.palette.background.line};

    .caption__btn {
      color: ${theme.palette.text.tertiary};
      background-color: ${theme.palette.background.neutral};

      :disabled {
        color: ${theme.palette.text.disabled};
        background-color: transparent;
      }
    }

    .caption__btn_next {
      margin-left: 14px;
    }

    .caption__month {
      width: max-content;
      text-transform: capitalize;
      cursor: pointer;
    }

    @media (max-width: ${theme.breakpoints.values.xxs}px) {
      padding: 16px 8px;
      margin: 0 -8px;

      .caption__btn_next {
        margin-left: 6px;
      }
    }
  `,
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div(
  () => css`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;

    display: flex;
    align-items: center;

    .service-book-date__right {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
    }

    .service-book-date__month {
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .service-book-date__start-time {
      font-weight: inherit;
    }
  `,
);

import { styled } from '@mui/material/styles';
import { Modal } from '@mui/base/Modal';

export const StyledAppModal = styled(Modal)(({ theme }) => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
}));

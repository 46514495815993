import { css } from '@emotion/react';
import styled from '@emotion/styled';
import CreatableSelect from 'react-select/creatable';

import { useAppSelectStyles } from 'components/app-selects/useAppSelectStyles';

export const StyledAppCreatableSelect = styled(CreatableSelect)(
  () => css`
    .app-creatable-select {
      ${useAppSelectStyles()}
    }
  `
);

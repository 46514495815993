import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import ruTranslation from './ru/translation.json';
import enTranslation from './en/translation.json';

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: enTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
  },
  detection: { caches: ['localStorage'] },
  debug: false,
});

export { i18n };

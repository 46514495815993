import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledControlBooking = styled.section(
  ({ theme }) => css`
    width: 100%;

    .control-booking__wrapper {
      width: 100%;
      display: inline-grid;
      grid-auto-flow: column;
      gap: 10px;
      margin-bottom: 30px;
    }

    .control-booking__list {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;

      border-radius: 10px;
      padding: 12px;
      box-shadow: ${theme.shadows[24]};

      display: inline-grid;
      grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
      justify-items: center;
    }

    .control-booking__element {
      text-align: center;
    }

    .control-booking__btn {
      display: grid;
      justify-content: center;
      justify-items: center;
      grid-gap: 8px;
      padding: 0;
      height: auto;

      text-decoration: none;
      font-size: 14px;
      text-wrap: balance;
      font-style: normal;
      font-weight: 500;
      line-height: 1.3;
      color: ${theme.palette.text.secondary};

      @media (any-hover: hover) {
        &:hover,
        &&:hover {
          color: ${theme.palette.text.primary};

          svg {
            color: ${theme.palette.primary.main};
            transition: color 0.4s ease;
          }
        }
      }

      svg {
        min-width: 30px;
        width: 30px;
        height: 30px;
        color: ${theme.palette.controls.dark};
        transition: color 0.4s ease;
      }
    }
  `,
);

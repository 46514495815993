import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { privateApi } from 'store/api';

import { AppNoFillingControlBtn } from 'components/ui-kit/AppNoFillingControlBtn';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';
import { joinClasses } from 'utils/joinClasses';
import { useSafeParams } from 'hooks/useSafeParams';
import { isApiError, isFetchBaseQueryError } from 'utils/network';
import { assertIsDefined } from 'utils/assertion';

import { CheckIcon, CrossIcon, StatusError } from 'assets/images';

import { StyledControlBookingDrawer } from './ControlBookingDrawer.styles';

type TControlBookingDrawer = {
  rentalOrderId: number;
  onCanceled: () => void;
  onClose: () => void;
};

export const ControlBookingDrawer = (props: TControlBookingDrawer) => {
  const { rentalOrderId, onCanceled, onClose } = props;
  const [isCanceled, setCanceled] = useState(false);

  const navigate = useNavigate();
  const { publicId } = useSafeParams();
  const [cancelOrder, { isLoading }] = privateApi.useCancelOrderMutation();

  const { t } = useTranslation();

  const cancelBookingHandler = async () => {
    try {
      assertIsDefined(publicId);

      await cancelOrder({
        public_id: publicId,
        rental_order_id: Number(rentalOrderId),
      }).unwrap();

      onCanceled();
      setCanceled(true);
    } catch (e) {
      if (isFetchBaseQueryError(e) && isApiError(e.data)) {
        toast.error(e.data.error?.message, {
          position: 'bottom-center',
        });
      } else {
        toast.error(t('BOOK_ERROR'), {
          position: 'bottom-center',
        });
      }
    }
  };

  const closeModalHandler = () => onClose();
  const onRebook = () => navigate(`/${publicId}`);

  return (
    <StyledControlBookingDrawer className="control-booking-drawer">
      <AppBtn
        className="control-booking-drawer__close"
        filling="ascetic"
        onClick={closeModalHandler}
        LeftIcon={CrossIcon}
      />
      <div className="control-booking-drawer__icon">
        {isCanceled ? <CheckIcon /> : <StatusError />}
      </div>

      <Typography
        className="control-booking-drawer__title"
        component="h2"
        color="text.secondary"
        fontSize={16}
        lineHeight={1.3}
        fontWeight={600}>
        {isCanceled ? t('CANCEL_SUCCESS') : `${t('CANCEL_BOOKING')} ?`}
      </Typography>

      <Typography
        className="control-booking-drawer__text"
        component="p"
        fontSize={14}
        lineHeight={1.3}
        fontWeight={400}>
        {isCanceled ? t('AGAIN_TEXT') : t('SURE_CANCEL_TEXT')}
      </Typography>

      {isCanceled ? (
        <AppBtn
          className="control-booking-drawer__control"
          onClick={onRebook}
          fullWidth>
          {t('REBOOK')}
        </AppBtn>
      ) : (
        <AppBtn
          className={joinClasses(
            'control-booking-drawer__control',
            'control-booking-drawer__control_cancel',
          )}
          onClick={cancelBookingHandler}
          fullWidth
          isLoading={isLoading}>
          {t('CANCEL_BOOKING')}
        </AppBtn>
      )}

      <AppNoFillingControlBtn
        className="control-booking-drawer__control"
        onClick={closeModalHandler}
        fullWidth>
        {t('CLOSE')}
      </AppNoFillingControlBtn>
    </StyledControlBookingDrawer>
  );
};

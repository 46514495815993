import React, { FC } from 'react';
import { ModalProps } from '@mui/material';

import { AppModalBackdrop } from 'components/app-modals/AppModalBackdrop';
import { AppFade } from 'components/app-transitions';

import { StyledAppModal } from './AppModal.styles';

export interface IAppModalProps extends ModalProps {
  isLoading?: boolean;
}

const AppModal: FC<IAppModalProps> = ({
  children,
  open,
  isLoading,
  onClose,
}) => {
  return (
    <StyledAppModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      disableEscapeKeyDown={isLoading}
      slots={{ backdrop: AppModalBackdrop }}
      onClose={isLoading ? undefined : onClose}
      closeAfterTransition
    >
      <AppFade in={open}>{children}</AppFade>
    </StyledAppModal>
  );
};

export default AppModal;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { IAppCheckboxProps } from './AppCheckbox';

export const StyledAppCheckbox = styled.label<
  Pick<IAppCheckboxProps, 'disabled' | 'size'>
>(
  ({ theme, disabled, size }) => css`
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 14px;
    line-height: 1.3;
    color: ${theme.palette.text.primary};
    -webkit-tap-highlight-color: transparent;
    border-radius: 3px;
    width: fit-content;
    cursor: pointer;
    pointer-events: ${disabled && 'none'};

    .app-checkbox {
      &__checkbox {
        appearance: none;
        width: ${size === 'm' ? '18px' : '24px'};
        height: ${size === 'm' ? '18px' : '24px'};
        border-radius: 3px;
        background-color: ${theme.palette.background.neutral};
        border: 2px solid ${theme.palette.background.line};
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition-property: background-color, border;
        transition-duration: ${theme.transitions.duration.shortest}ms;
        transition-timing-function: ease-out;
        cursor: pointer;

        @media (any-hover: hover) {
          &:hover {
            border-color: ${theme.palette.primary.light};
          }

          &:checked:hover,
          &:indeterminate:hover {
            background-color: ${theme.palette.primary.light};
            border-color: ${theme.palette.primary.light};
          }
        }

        &:checked {
          background-color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.main};
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath stroke='%23F6F9FA' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m11.667 4.084-6.416 6.417-2.917-2.917'/%3E%3C/svg%3E");
          background-position: center;
          background-repeat: no-repeat;
          background-size: calc(100% - 4px);
        }

        &:indeterminate {
          background-color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.main};
        }

        &:indeterminate::after {
          content: '';
          width: calc(100% - 6px);
          height: 2px;
          border-radius: 2px;
          background-color: ${theme.palette.background.primary};
        }

        &:disabled {
          background-color: ${theme.palette.background.primary};
          border-color: ${theme.palette.background.line};
          pointer-events: none;
          color: ${theme.palette.text.disabled};
        }

        &:disabled:checked {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath stroke='%23CDCADC' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m11.667 4.084-6.416 6.417-2.917-2.917'/%3E%3C/svg%3E");
        }
      }
    }
  `
);

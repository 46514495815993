import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledAppLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 16px;
    border-radius: 3em;
    display: inline-flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    transition-property: color, transform, text-decoration-color;
    transition-timing-function: ease-out;
    transition-duration: ${theme.transitions.duration.shortest}ms;
    -webkit-tap-highlight-color: transparent;
    text-decoration-color: transparent;

    &:active {
      color: ${theme.palette.primary.dark};
    }

    .app-link {
      &__icon {
        width: auto;
        height: calc(1em * 1.125);
        display: block;
        flex-shrink: 0;
      }

      &__icon *[stroke] {
        stroke: currentColor;
      }

      &__icon *[fill] {
        fill: currentColor;
      }
    }

    @media (any-hover: hover) {
      &:hover {
        text-decoration-color: currentColor;
      }
    }
  `
);

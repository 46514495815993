import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { IAppSwitchProps } from './AppSwitch';

export const StyledAppSwitch = styled.div<
  Pick<IAppSwitchProps, 'height' | 'reversed'>
>(
  ({ theme, height = 20, reversed }) => css`
    display: flex;
    align-items: center;
    font-size: 20px;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    border-radius: 3em;
    ${reversed ? 'flex-direction: row-reverse;' : null}

    .app-switch {
      &__label {
        cursor: pointer;

        ${reversed ? 'padding-right: 8px;' : 'padding-left: 8px;'}
      }

      &__switcher {
        position: relative;
        font-size: inherit;
        appearance: none;
        height: ${height}px;
        width: ${(height / 10) * 18}px;
        margin: 0;
        border-radius: 3em;
        background-color: ${theme.palette.controls.dark};
        border: 1px solid ${theme.palette.controls.dark};
        transition-property: background-color, border, transform;
        transition-duration: ${theme.transitions.duration.shortest}ms;
        transition-timing-function: ease-out;
        cursor: pointer;

        @media (any-hover: hover) {
          &:hover {
            background-color: ${theme.palette.controls.selected};
            border: 1px solid ${theme.palette.controls.selected};
          }

          &:checked:hover {
            background-color: ${theme.palette.primary.light};
            border: 1px solid ${theme.palette.primary.light};
          }
        }

        &::before {
          content: '';
          display: block;
          position: absolute;

          aspect-ratio: 1;
          @supports not (aspect-ratio: 1) {
            padding-left: 45%;
            height: 90%;
          }
          border-radius: 50%;
          background-color: ${theme.palette.background.default};
          box-shadow: ${theme.shadows[2]};
          transition: transform ${theme.transitions.duration.shortest}ms
            ease-out;

          ${height === 24
            ? css`
                left: 1px;
                top: 1px;
                bottom: 1px;
              `
            : css`
                left: 2px;
                top: 2px;
                bottom: 2px;
              `}
        }
      }

      &__switcher:checked {
        background-color: ${theme.palette.primary.main};
        border: 1px solid ${theme.palette.primary.main};

        &::before {
          transform: translate(95%, 0);

          transition-property: transform;
          transition-duration: ${theme.transitions.duration.shortest}ms;
          transition-timing-function: ease-out;
        }
      }

      &__switcher:disabled {
        pointer-events: none;
        background-color: ${theme.palette.background.neutral};
        border: 1px solid ${theme.palette.background.neutral};

        & + label {
          pointer-events: none;
        }
      }

      &__switcher:disabled:checked {
        &::before {
          transform: translate(95%, 0);
        }
      }
    }
  `,
);

import React from 'react';
import { t } from 'i18next';
import { Typography } from '@mui/material';

import { AppCustomModal } from 'components/app-modals';
import AppLink from 'components/ui-kit/AppLink/AppLink';
import { usePhone } from 'hooks/usePhone';

import {
  PhoneCallIcon,
  WhatsAppIcon,
  DangerCircleIcon,
  FilledCloseIcon,
} from 'assets/images';

import { StyledContainer } from './BookingErrorModal.styles';

export type TBookingErrorModalProps = {
  onClose: () => void;
  open: boolean;
};
const BookingErrorModal = ({ onClose, open }: TBookingErrorModalProps) => {
  const { phone } = usePhone();
  const { number, whatsAppLink, callLink } = phone;
  return (
    <AppCustomModal isOpen={open} onClose={onClose}>
      <StyledContainer>
        <div className="booking-error-modal__wrapper">
          <div className="booking-error-modal__title-wrapper">
            <DangerCircleIcon />
            <Typography
              component="h2"
              width="100%"
              textAlign="center"
              fontSize={14}
              fontWeight={400}
              color="text.invertedPrimary">
              {t('PROBLEM_WITH_ONLINE_BOOKING', {
                number,
              })}
            </Typography>
            <button
              type="button"
              onClick={onClose}
              className="booking-error-modal__close-btn">
              <FilledCloseIcon />
            </button>
          </div>

          <div className="booking-error-modal__btn-wrapper">
            <AppLink
              to={whatsAppLink}
              target="_blank"
              LeftIcon={() => <WhatsAppIcon color="#0DC143" />}
              className="booking-error-modal__link">
              {t('WRITE')}
            </AppLink>
            <AppLink
              to={callLink}
              target="_blank"
              LeftIcon={() => <PhoneCallIcon color="text.primary" />}
              className="booking-error-modal__link">
              {t('CALL')}
            </AppLink>
          </div>
        </div>
      </StyledContainer>
    </AppCustomModal>
  );
};

export default BookingErrorModal;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { add, differenceInSeconds, isBefore } from 'date-fns';

import { joinClasses } from 'utils/joinClasses';
import { IUserInfo } from 'types/types';

import { StatusSuccess, StatusError } from 'assets/images';

import { StyledResultStatus } from './ResultStatus.styles';

type TResultStatus = {
  className?: string;
  isCanceled: boolean;
  isSuccess: boolean;
  isPending: boolean;
  isTimeLeft: boolean;
  isAdmin: boolean;
  isError: boolean;
  number?: string;
  user?: IUserInfo;
  timeLeft?: number;
  isPaymentOnlineCurrent: boolean;
  isPaymentOnlineAll: boolean;
};

export const ResultStatus = (props: TResultStatus) => {
  const {
    className,
    isCanceled,
    isSuccess,
    isPending,
    isTimeLeft,
    isAdmin,
    user,
    timeLeft,
    isPaymentOnlineCurrent,
    isPaymentOnlineAll,
  } = props;
  const { t } = useTranslation();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const payByDateRef = useRef(add(new Date(), { seconds: timeLeft }));
  const [currentDate, setCurrentDate] = useState(new Date());

  const colorTitle = isCanceled ? 'text.primary' : 'success.main';

  const isCalcTime =
    timeLeft && isPending && isBefore(currentDate, payByDateRef.current);

  useEffect(() => {
    if (isCalcTime) {
      timeoutRef.current = setTimeout(() => {
        setCurrentDate(new Date());
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isCalcTime, currentDate]);

  const remainingTime = useMemo(() => {
    const formatTime = (seconds: number) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = (seconds % 60).toFixed();

      if (hours > 0) {
        return `${hours} ч ${minutes} мин ${secs} сек`;
      } else {
        return `${minutes} мин ${secs} сек`;
      }
    };

    const seconds = Math.max(
      0,
      differenceInSeconds(payByDateRef.current, currentDate),
    );

    return formatTime(seconds);
  }, [currentDate]);

  return (
    <StyledResultStatus
      className={joinClasses(
        className,
        'result-status',
        !isCanceled && 'result-status_success',
        isPending && 'result-status_pending',
      )}>
      {isCanceled ? <StatusError /> : <StatusSuccess />}

      <Typography
        className="result-status__title"
        component="h2"
        color={colorTitle || ''}
        fontSize={20}
        lineHeight={1.3}
        fontWeight={600}>
        {isCanceled ? t('CANCELED_TITLE') : t('SUCCESS_TITLE')}
      </Typography>

      <Typography
        className="result-status__text"
        component="p"
        fontSize={14}
        fontWeight={400}
        lineHeight={1.3}
        color="text.primary">
        {isSuccess &&
          isPaymentOnlineAll &&
          (user?.email
            ? t('SUCCESS_TEXT_EMAIL', { email: user?.email })
            : t('SUCCESS_TEXT_PHONE', { phone: user?.phone }))}

        {isPending &&
          !isPaymentOnlineCurrent &&
          isPaymentOnlineAll &&
          !isAdmin &&
          t('PENDING_TEXT_OFFLINE')}

        {isPending &&
          !isCalcTime &&
          isPaymentOnlineCurrent &&
          isPaymentOnlineAll &&
          !isSuccess &&
          !isAdmin &&
          !isCanceled &&
          t('TIME_LEFT_TEXT')}

        {/* {isPending && isPaymentOnlineCurrent && !isAdmin && !isCanceled && t('PENDING_TEXT', { time: remainingTime })} */}

        {(isCanceled && !isAdmin && t('CANCELED_TEXT')) ||
          (isCanceled && !isAdmin && t('CANCELED_TEXT_TIME'))}
      </Typography>

      {isAdmin && !isCanceled && (
        <Typography
          className="result-status__text"
          component="p"
          fontSize={14}
          fontWeight={400}
          lineHeight={1.3}
          color="text.primary">
          Вы можете так же скопировать ссылку и отправить любым удобным способом
          ниже
        </Typography>
      )}
    </StyledResultStatus>
  );
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledDayPartSlots = styled.div(
  () => css`
    display: grid;
    gap: 10px;
  `,
);

export const Slots = styled.div(
  () => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  `,
);

export const SlotItem = styled.label<{
  checked: boolean;
  disabled: boolean;
}>(({ theme, disabled, checked }) => {
  const getStateStyle = () => {
    if (disabled) {
      return css`
        background-color: ${theme.palette.controls.disabled};
        border: 1px solid ${theme.palette.controls.disabled};
      `;
    } else if (checked) {
      return css`
        background-color: ${theme.palette.controls.selected};
        border: 1px solid ${theme.palette.primary.main};
        cursor: pointer;
      `;
    } else {
      return css`
        background-color: ${theme.palette.background.neutral};
        border: 1px solid ${theme.palette.background.line};
        cursor: pointer;
      `;
    }
  };

  return css`
    position: relative;
    padding: 13px 30px;

    border-radius: 30px;
    text-align: center;

    @media (max-width: ${theme.breakpoints.values.xs}px) {
      padding: 13px;
    }

    ${getStateStyle()}

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  `;
});

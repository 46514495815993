import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledServiceMiniCard = styled.section(
  ({ theme }) => css`
    display: flex;

    .service-mini-card__amount {
      display: inline-block;
      margin: 0;
      font-weight: 600;
      color: ${theme.palette.primary.main};
    }
  `,
);

export const ImgFigure = styled.figure(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-right: 10px;

    border-radius: 6px;
    border: 1px solid ${theme.palette.background.line};
    background: #fff;
    padding: 6px 10px;

    width: 70px;
    height: 45px;

    img {
      max-width: 65px;
      max-height: 30px;
    }
  `,
);

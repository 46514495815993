import React from 'react';

import AppBtn from 'components/ui-kit/AppBtn/AppBtn';

import { StyledStepAction } from './StepAction.styles';

interface IStepActionProps {
  label: string;
  onSubmit?: () => void;
  type?: 'button' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
}

export const StepAction = ({
  label,
  onSubmit,
  type = 'button',
  disabled,
  isLoading,
  children,
}: IStepActionProps) => {
  return (
    <StyledStepAction>
      {children}
      <AppBtn
        fullWidth
        disabled={disabled}
        isLoading={isLoading}
        type={type}
        onClick={onSubmit}
      >
        {label}
      </AppBtn>
    </StyledStepAction>
  );
};

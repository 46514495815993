import { css } from '@emotion/react';
import styled from '@emotion/styled';

import AppBtn from 'components/ui-kit/AppBtn/AppBtn';

export const Root = styled.form(
  () => css`
    flex: 1;
    display: flex;
    flex-direction: column;
  `
);

export const RootHeader = styled.header(
  () => css`
    text-align: center;
    padding: 20px 0;
  `
);

export const RootContent = styled.header(
  ({ theme }) => css`
    padding: 0 16px 40px;

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      padding: 0 20px 40px;
    }
  `
);

export const ServiceItem = styled.header(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-bottom: 1px solid ${theme.palette.background.line};
  `
);

export const AddSetBtn = styled(AppBtn)(
  ({ theme }) => css`
    margin-top: 16px;
  `
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import AppBtn from 'components/ui-kit/AppBtn/AppBtn';


export const StyledSelectSlot = styled.section(
  () => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 20px;
  `
);

export const DatePickerContainer = styled.div(
  () => css`
    display: flex;
    justify-content: center;
    z-index: 10;
    padding: 0 20px;

    .datepicker__elem {
      width: 100%;
    }
  `
);

export const TimeSlotsContainer = styled.div(
  ({ theme }) => css`
    padding: 20px;
    background-color: ${theme.palette.background.default};
  `
);

export const StyledAppBtn = styled(AppBtn)`
  font-size: 14px;
  line-height: 18.2px;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSafeParams } from 'hooks/useSafeParams';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';
import { isAdminRoute } from 'features/admin/utils/isAdmin';
import { hasAdminToken } from 'utils/token';

import { CalendarAddIcon, CloseIcon, AgainIcon } from 'assets/images';

import { StyledControlBooking } from './ControlBooking.styles';

type TControlBooking = {
  isSuccess: boolean;
  isPending: boolean;
  isCanceled: boolean;
  isPaymentOnline: boolean;
  isPaymentOnlineCurrent: boolean;
  isTimeLeft: boolean;
  isError: boolean;
  isCancelableVisit: boolean;
  googleLink: string | undefined;
  paymentPageUrl: string;
  onCancel: () => void;
};

export const ControlBooking = (props: TControlBooking) => {
  const {
    onCancel,
    isPending,
    isCanceled,
    isPaymentOnline,
    isCancelableVisit,
    googleLink,
    paymentPageUrl,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { publicId } = useSafeParams();

  const isPermissionCancel =
    isAdminRoute() && hasAdminToken() ? false : !isCancelableVisit;

  const onGoToPay = () => {
    window.location.href = paymentPageUrl;
  };

  return (
    <StyledControlBooking className="control-booking">
      <h2 className="visually-hidden">{t('CONTROL_BOOKING')}</h2>

      {isPending && isPaymentOnline && !isCanceled && (
        <div className="control-booking__wrapper">
          <AppBtn fullWidth onClick={onGoToPay}>
            {t('PAY')}
          </AppBtn>
        </div>
      )}

      {isCanceled && (
        <div className="control-booking__wrapper">
          <AppBtn fullWidth onClick={() => navigate(`/${publicId}`)}>
            Записаться заново
          </AppBtn>
        </div>
      )}

      {!isCanceled && (
        <ul className="control-booking__list">
          {isPending && (
            <li className="control-booking__element">
              <AppBtn
                className="control-booking__btn"
                filling="ascetic"
                LeftIcon={CloseIcon}
                onClick={onCancel}
                disabled={isPermissionCancel}>
                {t('CANCEL_BOOKING')}
              </AppBtn>
            </li>
          )}
          <li className="control-booking__element">
            <AppBtn
              className="control-booking__btn"
              filling="ascetic"
              LeftIcon={AgainIcon}
              onClick={() => navigate(`/${publicId}`)}>
              {t('AGAIN_BOOKING')}
            </AppBtn>
          </li>
          <li className="control-booking__element">
            {googleLink ? (
              <a
                href={googleLink}
                target="_blank"
                rel="noreferrer"
                className="control-booking__btn">
                <CalendarAddIcon />
                {t('ADD_TO_CALENDAR')}
              </a>
            ) : (
              <AppBtn
                className="control-booking__btn"
                filling="ascetic"
                LeftIcon={CalendarAddIcon}
                disabled>
                {t('ADD_TO_CALENDAR')}
              </AppBtn>
            )}
          </li>
        </ul>
      )}
    </StyledControlBooking>
  );
};

import React, { useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { publicApi } from 'store/api';

import { useSafeParams } from 'hooks/useSafeParams';
import { IRentalItem, ITimeSlot } from 'types/types';
import { OrderDetails, StepAction } from 'features/order';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { AppWeekDatePicker } from 'components/ui-kit/AppWeekDatePicker';
import { Header } from 'components/Header';
import { parseApiDate } from 'utils/parseApiDate';

import { TimeSlots } from '../TimeSlots';
import { isSlotDisabled } from '../utils';

import {
  DatePickerContainer,
  StyledSelectSlot,
  TimeSlotsContainer,
  StyledAppBtn,
} from './SelectSlot.styles';

const todayDate = new Date();

interface ISelectSlotProps {
  onBackClick: () => void;
  defaultSlots: ITimeSlot[];
  services: IRentalItem[];
  onSubmit: (slots: ITimeSlot[]) => void;
  canSubmitEmpty?: boolean;
}

export const SelectSlot = ({
  onBackClick,
  defaultSlots,
  onSubmit,
  services,
  canSubmitEmpty = false,
}: ISelectSlotProps) => {
  const { t } = useTranslation();
  const { publicId } = useSafeParams();

  const defaultDate = useMemo(() => {
    if (defaultSlots.length > 0) {
      return parseApiDate(defaultSlots[0].from);
    }

    return new Date();
  }, [defaultSlots]);

  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [selectedSlots, setSelectedSlots] = useState<ITimeSlot[]>(defaultSlots);

  const { data, isFetching } = publicApi.useGetAvailableSlotsQuery(
    publicId
      ? {
          public_id: publicId,
          items: services.map((item) => item.id),
          slot_date: format(selectedDate, 'yyyy-MM-dd'),
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const isAllSlotsDisabled = useMemo(
    () => data && data.data.result.every(isSlotDisabled),
    [data]
  );

  return (
    <>
      <Header
        onBackClick={onBackClick}
        action={
          selectedSlots.length > 0 ? (
            <StyledAppBtn
              onClick={() => setSelectedSlots([])}
              filling="ascetic"
              type="button"
              className=""
            >
              {t('CLEAR')}
            </StyledAppBtn>
          ) : null
        }
      >
        <Typography
          component="h2"
          width="100%"
          textAlign="center"
          fontSize={16}
          fontWeight={600}
          color="text.secondary"
        >
          {t('SEL_DATETIME')}
        </Typography>
      </Header>
      <StyledSelectSlot>
        <DatePickerContainer>
          <AppWeekDatePicker
            className="datepicker__elem"
            selected={selectedDate}
            onSelect={(date) => setSelectedDate(date ?? new Date())}
            fromDate={todayDate}
          />
        </DatePickerContainer>
        {isFetching ? (
          <AppSpinner />
        ) : data ? (
          isAllSlotsDisabled ? (
            <Typography
              marginTop={3}
              marginBottom={3}
              fontSize={16}
              textAlign="center"
              color="text.secondary"
            >
              {t('ALL_SLOTS_DISABLED')}
            </Typography>
          ) : (
            <TimeSlotsContainer>
              <TimeSlots
                timeSlots={data.data.result}
                selected={selectedSlots}
                onSelect={setSelectedSlots}
              />
            </TimeSlotsContainer>
          )
        ) : null}

        <StepAction
          disabled={selectedSlots.length === 0 && !canSubmitEmpty}
          onSubmit={() => onSubmit(selectedSlots)}
          label={t('NEXT')}
        >
          {services.length > 0 ? (
            <OrderDetails
              services={services}
              slots={selectedSlots}
              additionalServiceOrders={[]}
            />
          ) : null}
        </StepAction>
      </StyledSelectSlot>
    </>
  );
};

import { format } from 'date-fns';

import { MESSAGE_SOCIAL_START, MESSAGE_SOCIAL_END } from 'constants/text';
import { parseApiDate } from 'utils/parseApiDate';

import { IResultStepOrderItem } from './types';

export const getEarliestDate = (orders: IResultStepOrderItem[]) => {
  if (orders.length === 0) {
    return {
      time: undefined,
      date: undefined,
      minDate: undefined,
    };
  }

  const allFrom = orders
    .map(order => order.slots)
    .flatMap(arr => arr.map(obj => obj.from));
  const minDate = Math.min(
    ...allFrom.map(date => parseApiDate(date).getTime()),
  );

  if (!minDate) {
    return {
      time: undefined,
      date: undefined,
      minDate: undefined,
    };
  }

  const time = format(new Date(minDate), 'HH:mm');
  const date = format(new Date(minDate), 'dd.MM.yyyy');
  return { time, date, minDate };
};

export const getMessageSocial = (orders: IResultStepOrderItem[]) => {
  return `${MESSAGE_SOCIAL_START} ${getEarliestDate(orders).date} в ${
    getEarliestDate(orders).time
  }${MESSAGE_SOCIAL_END}`;
};

export const countdownTimer = (hoursDecimal: number) => {
  const totalSeconds = Math.abs(hoursDecimal) * 3600;

  function formatTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = (seconds % 60).toFixed(2);
    return `${hours} ч ${minutes} мин ${secs} сек`;
  }

  const formattedTime = formatTime(totalSeconds);
  return { totalSeconds, formattedTime };
};

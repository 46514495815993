export enum Paths {
    Root = ':publicId',
    Services = 'services',
    Slots = 'slots',
    Result = 'result',
    Admin = 'admin/',
    EditBook = 'edit-book',
    QuickBook = 'quick-book',
    SignIn = '/lk/sign-in',
}

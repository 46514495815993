import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledOrderDetails = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
);

export const Times = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
  `
);

export const TimeDetails = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `
);

export const ServiceCount = styled.div(
  ({ theme }) => css`
    margin-left: 2px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    padding: 2px 4px;
    background-color: ${theme.palette.primary.lighter};
    color: ${theme.palette.text.tertiary};
  `
);

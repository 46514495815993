import styled from '@emotion/styled';

export const StyledFeedback = styled.section`
  text-align: center;

  .feedback__text {
    margin: 0 auto 10px;
  }

  .feedback__wrapper {
    display: grid;
    grid-auto-flow: column;
    gap: 6px;
    justify-content: center;
    margin: 0 auto;
  }

  .feedback__link {
    padding: 11px 22px;
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    svg {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }
`;

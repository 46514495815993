import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { useDateLocale } from 'i18n/useDateLocale';
import { parseApiDate } from 'utils/parseApiDate';

type TTimeRange = {
  slot: {
    from: string;
    to: string;
  };
};

export const TimeRange = ({ slot }: TTimeRange) => {
  const { from, to } = slot;
  const { t } = useTranslation();
  const locale = useDateLocale();

  const fromTime = format(parseApiDate(from), 'HH:mm', {
    locale,
  });
  const toTime = format(parseApiDate(to), 'HH:mm', {
    locale,
  });

  return (
    <>
      <Typography
        component="span"
        fontSize={16}
        fontWeight={400}
        color="text.secondary"
      >
        {t('SET')}&#160;
      </Typography>

      <Typography
        component="time"
        fontSize={16}
        fontWeight={500}
        color="text.secondary"
        dateTime={`${fromTime} ${toTime}`}
      >
        {fromTime} &#8211; {toTime}
      </Typography>
    </>
  );
};

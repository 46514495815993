import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.ul(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    list-style: none;

    display: grid;
    gap: 10px;

    .service-slots__element {
      display: grid;
      gap: 10px;

      padding: 14px;
      border-radius: 10px;
      border: 1px solid ${theme.palette.background.line};
      text-align: center;
    }

    .service-slots__wrap {
      display: grid;
      grid-template-columns: auto auto;
      gap: 4px;
      grid-template-rows: auto auto;
      justify-content: space-between;
      line-height: 1.3;
    }

    .service-slots__date {
      font-size: 14px;
      text-align: left;
      font-weight: 500;
      color: ${theme.palette.text.tertiary};
    }

    .service-slots__slot {
      margin: 0;

      font-size: 16px;
      font-weight: 400;
      color: ${theme.palette.text.secondary};
    }

    .service-slots__time {
      font-weight: 500;
    }

    .service-slots__total {
      margin: 0;
      grid-column: 2 / 3;
      grid-row: 1/-1;
      justify-self: right;
      align-self: center;

      font-size: 16px;
      font-weight: 600;
      color: ${theme.palette.text.secondary};
    }

    .service-slots__services {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      color: ${theme.palette.text.secondary};

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    .service-slots__service-name {
      margin: 0;
    }

    .service-slots__service-price {
      margin: 0;
      font-weight: 600;
    }
  `
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import AppControlBtn from 'components/ui-kit/AppControlBtn/AppControlBtn';

export const AppNoFillingControlBtn = styled(AppControlBtn)(
  ({ theme }) => css`
    background-color: transparent;
    font-size: 16px;
    color: ${theme.palette.primary.main};

    .app-btn__icon {
      color: inherit;
    }
  `
);

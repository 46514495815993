import React, { createContext, useContext, useMemo, useState } from 'react';
import {
  CaptionProps,
  Day,
  DayPicker,
  DayPickerSingleProps,
  RowProps,
  SelectSingleEventHandler,
  useDayPicker,
  useNavigation,
} from 'react-day-picker';
import {
  add,
  format,
  getWeek,
  startOfMonth,
  sub,
  getWeekOfMonth,
  getWeeksInMonth,
  max,
} from 'date-fns';
import { IconButton, Typography } from '@mui/material';

import { useDateLocale } from 'i18n/useDateLocale';
import { joinClasses } from 'utils/joinClasses';

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'assets/images';

import { StyledCaption, StyledDatePicker } from './AppWeekDatePicker.styles';

const CurrentWeek = createContext<{
  weekDate: Date;
  setWeekDate: (value: Date) => void;
}>(undefined as any);
const IsOpenMonth = createContext<{
  open: boolean;
  toggle: () => void;
}>(undefined as any);

export const AppWeekDatePicker = ({
  className,
  onSelect,
  selected,
  ...rest
}: Omit<DayPickerSingleProps, 'mode'>) => {
  const locale = useDateLocale();
  const [currentWeek, setCurrentWeek] = useState(selected ?? new Date());
  const [displayMonth, setDisplayMonth] = useState(selected ?? new Date());
  const [openMonth, setOpenMonth] = useState(false);

  const onInternalSelect: SelectSingleEventHandler = (
    day,
    selectedDay,
    activeModifiers,
    evt,
  ) => {
    onSelect?.call(onSelect, day, selectedDay, activeModifiers, evt);
    setCurrentWeek(day || new Date());

    if (day) {
      setDisplayMonth(day);
    }
  };

  const isOpenMonth = !openMonth ? 'rdp-collapse' : null;

  return (
    <CurrentWeek.Provider
      value={{
        weekDate: currentWeek,
        setWeekDate: setCurrentWeek,
      }}>
      <IsOpenMonth.Provider
        value={{
          open: openMonth,
          toggle: () => setOpenMonth(prev => !prev),
        }}>
        <StyledDatePicker
          className={joinClasses(className, isOpenMonth)}
          weeksInMonth={getWeeksInMonth(displayMonth, {
            weekStartsOn: 1,
          })}>
          <DayPicker
            mode="single"
            locale={locale}
            showOutsideDays
            components={{
              Caption: CustomCaption,
              Row: CustomRow,
            }}
            onSelect={onInternalSelect}
            selected={selected}
            month={displayMonth}
            onMonthChange={setDisplayMonth}
            {...rest}
          />
        </StyledDatePicker>
      </IsOpenMonth.Provider>
    </CurrentWeek.Provider>
  );
};

const CustomCaption = ({ displayMonth }: CaptionProps) => {
  const locale = useDateLocale();
  const { weekDate, setWeekDate } = useContext(CurrentWeek);
  const { open, toggle } = useContext(IsOpenMonth);
  const { goToMonth, previousMonth } = useNavigation();
  const { fromDate, onSelect } = useDayPicker();

  const onPrev = (event: React.MouseEvent) => {
    let selected: Date;

    if (open) {
      const prevMonth = sub(displayMonth, { months: 1 });
      selected = startOfMonth(prevMonth);
    } else {
      selected = sub(weekDate, { weeks: 1 });
    }

    selected = fromDate ? max([selected, fromDate]) : selected;

    goToMonth(selected);
    setWeekDate(selected);
    (onSelect as SelectSingleEventHandler)?.(selected, selected, {}, event);
  };

  const onNext = (event: React.MouseEvent) => {
    let selected: Date;

    if (open) {
      const nextWeek = add(displayMonth, { months: 1 });
      selected = startOfMonth(nextWeek);
    } else {
      selected = add(weekDate, { weeks: 1 });
    }

    goToMonth(selected);
    setWeekDate(selected);
    (onSelect as SelectSingleEventHandler)?.(selected, selected, {}, event);
  };

  const disabledPrev = useMemo(() => {
    if (previousMonth) {
      return false;
    }

    if (fromDate) {
      return (
        getWeek(weekDate, {
          weekStartsOn: 1,
          firstWeekContainsDate: 4,
        }) <=
        getWeek(fromDate, {
          weekStartsOn: 1,
          firstWeekContainsDate: 4,
        })
      );
    }

    return getWeekOfMonth(weekDate) === 1;
  }, [fromDate, previousMonth, weekDate]);

  const isOpen = open ? 'caption__icon_open' : null;

  return (
    <StyledCaption>
      <div className="caption__wrapper" onClick={toggle}>
        <Typography
          className="caption__month"
          fontSize={16}
          fontWeight={500}
          color="text.tertiary">
          {format(displayMonth, 'LLLL yyy', { locale })}
        </Typography>

        <ChevronDownIcon
          width={24}
          height={24}
          className={joinClasses('caption__icon', isOpen)}
        />
      </div>

      <div>
        <IconButton
          size="small"
          disabled={disabledPrev}
          onClick={onPrev}
          aria-label="prev"
          className="caption__btn">
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={onNext}
          aria-label="next"
          className="caption__btn caption__btn_next">
          <ChevronRightIcon />
        </IconButton>
      </div>
    </StyledCaption>
  );
};

const CustomRow = (props: RowProps) => {
  const { dates, displayMonth } = props;
  const { weekDate } = useContext(CurrentWeek);
  const monday = dates[0];

  const isActiveWeek =
    getWeek(monday as Date, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    }) ===
    getWeek(weekDate, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    });

  const isInactive = !isActiveWeek ? 'rdp-row-inactive' : null;

  return (
    <tr className={joinClasses('rdp-row', isInactive)}>
      {dates.map((item, index) => (
        <td key={index} className="rdp-cell" role="presentation">
          <Day displayMonth={displayMonth} date={item} />
        </td>
      ))}
    </tr>
  );
};

import { Global, css, useTheme } from '@emotion/react';
import React from 'react';

const ToastStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        :root {
          --toastify-color-info: ${theme.palette.primary.main};
          --toastify-color-success: ${theme.palette.success.main};
          --toastify-color-warning: ${theme.palette.warning.main};
          --toastify-color-error: ${theme.palette.error.light};
        }

        .Toastify--animate {
          animation-duration: ${theme.transitions.duration.shortest}ms;
        }
      `}
    />
  );
};

export default ToastStyles;

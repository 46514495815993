import React, { useLayoutEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { publicApi } from 'store/api';

import { useSafeParams } from 'hooks/useSafeParams';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import AppToast from 'components/ui-kit/AppToast/AppToast';

function App() {
  const [ready, setReady] = useState(false);
  const { t, i18n } = useTranslation();
  const { publicId } = useSafeParams();
  const { data, isLoading, isError } = publicApi.useGetUnitedDataQuery(
    publicId
      ? {
          public_id: publicId,
        }
      : skipToken
  );

  useLayoutEffect(() => {
    if (data) {
      // Предзагружаем изображения катеров
      data.data.rental_items.forEach((item) => {
        const img = new Image();
        img.src = item.params.image_url;
        (window as any)[item.params.image_url] = img; // Хак для предотвращения перезагрузки
      });

      i18n.addResourceBundle('ru', 'translation', data.data.locales.ru);
      // TODO доработать локализацию
      // i18n.addResourceBundle('en', 'translation', data.data.locales.en); 
      setReady(true);
    }
  }, [data, i18n]);

  if (isError) {
    return (
      <Typography
        marginTop={4}
        textAlign="center"
        fontSize={16}
        color="text.primary"
      >
        {t('APP_NOT_AVAILABLE')}
      </Typography>
    );
  }

  if (isLoading || !ready) {
    return <AppSpinner />;
  }

  return (
    <>
      <Outlet />
      <AppToast />
    </>
  );
}

export default App;

import { useState } from 'react';

export const usePhoneMask = () => {
    const [maxLength, setMaxLength] = useState(21);

    const getInputNumbersValue = (value: string) => value.replace(/\D/g, '');

    const formatRuPhoneNumber = (inputNumber: string) => {
        let formatted = '+7';
        if (inputNumber.length > 1) {
            formatted += ` (${inputNumber.substring(1, 4)}`;
        }
        if (inputNumber.length >= 5) {
            formatted += `) ${inputNumber.substring(4, 7)}`;
        }
        if (inputNumber.length >= 8) {
            formatted += `-${inputNumber.substring(7, 9)}`;
        }
        if (inputNumber.length >= 10) {
            formatted += `-${inputNumber.substring(9, 11)}`;
        }
        return formatted;
    };

    const formatInternationalPhoneNumber = (inputNumber: string) => {
        let formatted = '+' + inputNumber.substring(0, 3);
        if (inputNumber.length > 3) {
            formatted += ` (${inputNumber.substring(3, 5)}`;
        }
        if (inputNumber.length > 5) {
            formatted += `) ${inputNumber.substring(5, 8)}`;
        }
        if (inputNumber.length > 8) {
            formatted += `-${inputNumber.substring(8, 10)}`;
        }
        if (inputNumber.length > 10) {
            formatted += `-${inputNumber.substring(10, 14)}`;
        }
        return formatted;
    };

    const onInputMaskPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target;
        const inputNumber = getInputNumbersValue(input.value);
        const selectionStart = input.selectionStart;

        if (!inputNumber) {
            input.value = '';
            return;
        }

        if (['7', '8', '9'].includes(inputNumber[0])) {
            if (inputNumber[0] === '9') {
                input.value = '+7 (' + inputNumber;
            }

            // редактирование в середине строки            
            if (input.value.length != selectionStart && input.value.length) {
                if ((event.nativeEvent as InputEvent).data && /\D/g.test((event.nativeEvent as InputEvent).data || '')) {
                    input.value = inputNumber; 
                }

                if (/\D/.test((event.nativeEvent as InputEvent).data || '')) {
                    switch (selectionStart) {
                    case 0:
                    case 1:
                        input.value = '+7';
                        break;
                    case 2:
                        input.value = '+7 ';
                        break;
                    case 3:
                        input.value = input.value.slice(0, 3);
                        break;
                    case 7:
                        input.value = input.value.slice(0, 7);
                        break;
                    case 8:
                        input.value = input.value.slice(0, 8);
                        break;
                    case 12:
                        input.value = input.value.slice(0, 12);
                        break;
                    case 15:
                        input.value = input.value.slice(0, 15);
                        break;
                    default:
                        break;
                    }
                }
                return;
            }
                        
            input.value = formatRuPhoneNumber(inputNumber);                        
            setMaxLength(18);
        } else if (['1', '2', '3', '4', '5', '6'].includes(inputNumber[0])) {
            
            // редактирование в середине строки 
            if (input.value.length != selectionStart && input.value.length) {
                if ((event.nativeEvent as InputEvent).data && /\D/g.test((event.nativeEvent as InputEvent).data || '')) {
                    input.value = inputNumber; 
                }
            
                if (/\D/.test((event.nativeEvent as InputEvent).data || '')) {
                    switch (selectionStart) {
            
                    case 5:
                        input.value = input.value.slice(0, 5);
                        break;
                    case 8:
                        input.value = input.value.slice(0, 8);
                        break;
                    case 9:
                        input.value = input.value.slice(0, 9);
                        break;
                    case 13:
                        input.value = input.value.slice(0, 13);
                        break;
                    case 16:
                        input.value = input.value.slice(0, 16);
                        break;
                    default:
                        break;
                    }
                }
                return;
            }

            input.value = formatInternationalPhoneNumber(inputNumber);
            setMaxLength(21);
        } else {
            return input.value = '';
        }
    };

    const onPhonePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const input = event.currentTarget as HTMLInputElement;
        const pastedText = event.clipboardData.getData('Text');
        const inputNumber = getInputNumbersValue(pastedText);
    
        if (/\D/g.test(pastedText)) {
            event.preventDefault();
            input.value = inputNumber;
        }
    };

    return { onInputMaskPhone, onPhonePaste, maxLength };
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.form(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    padding: 14px;
    box-shadow: ${theme.shadows[24]};
    border-radius: 10px;

    & + & {
      margin-top: 8px;
    }
  `
);

export const Price = styled.div(
  () => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

export const Slots = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  `
);

export const SlotItem = styled.div(
  () => css`
    display: flex;
    align-items: center;

    & + & {
      margin-top: 20px;
    }

    .slot-switch {
      justify-content: space-between;
      flex: 1;
    }
  `
);

import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IRentalItem } from 'types/types';
import AppCheckbox from 'components/ui-kit/AppCheckbox/AppCheckbox';

import { StyledServiceCard } from './ServiceCard.styles';

interface IServiceCardProps {
  item: IRentalItem;
  selected: boolean;
  onSelect: (checked: boolean) => void;
}

export const ServiceCard = ({
  onSelect,
  selected,
  item,
}: IServiceCardProps) => {
  const { t } = useTranslation();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(event.target.checked);
  };

  return (
    <StyledServiceCard selected={selected} className="serviceCard">
      <input
        className="control-checkbox"
        type="checkbox"
        checked={selected}
        onChange={onChange}
      />
      <figure className="serviceCard__img">
        <img src={item.params.image_url} alt={item.name} decoding="sync" loading="eager" />
      </figure>
      <div className="serviceCard__wrapper">
        <div className="serviceCard__wrapper__title">
          <Typography
            component="h3"
            color="text.secondary"
            fontSize={20}
            lineHeight="26px"
            fontWeight={600}
          >
            {item.name}
          </Typography>
          <div className="serviceCard__text">
            <Typography
              className="serviceCard__description"
              color="text.tertiary"
              component="span"
              fontSize={14}
            >
              {item.description}
            </Typography>{' '}
            <Typography
              className="serviceCard__price"
              component="span"
              fontSize={16}
              fontWeight={600}
              color="text.accent"
            >
              {t('PRICE', {
                amount: Number(item.price).toLocaleString('ru', {
                  style: 'decimal',
                }),
              })}
            </Typography>
          </div>
        </div>
        <AppCheckbox size="l" checked={selected} onChange={onChange} />
      </div>
    </StyledServiceCard>
  );
};

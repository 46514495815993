import QRCode from 'qrcode';
import FileSaver from 'file-saver';

export const convertUrlToQrImgSrc = (
  url: string,
  qrColor?: string,
  handleErr?: (err: unknown) => void
) => {
  let imgSrc: string | null = null;

  QRCode.toString(
    url,
    { type: 'svg', color: { dark: qrColor || '#4A3C73' } },
    (err, svg) => {
      if (err && handleErr) {
        handleErr(err);
        return;
      }

      imgSrc = 'data:image/svg+xml,' + encodeURIComponent(svg);
    }
  );

  return imgSrc;
};

export const downloadQr = (
  link: string,
  fileName?: string,
  handleErr?: (err: unknown) => void,
  qrColor?: string
) => {
  QRCode.toString(
    link,
    { type: 'svg', color: { dark: qrColor || '#4A3C73' } },
    (err, svg) => {
      if (err) {
        handleErr && handleErr(err);
        return;
      }

      const blob = new Blob([svg]);
      FileSaver.saveAs(blob, (fileName || 'qr') + '.svg');
    }
  );
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledSocial = styled.ul(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    align-items: center;
    justify-content: center;

    .share-social__element + .share-social__element {
      margin-left: 20px;
    }

    .share-social__link {
      display: block;
      min-width: 30px;
      height: 30px;
      transition: opacity 0.4 ease;
      color: ${theme.palette.primary.main};

      &:hover,
      &:active,
      &:focus {
        opacity: 0.8;
        transition: opacity 0.4 ease;
      }
    }
  `,
);

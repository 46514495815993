import React from 'react';
import { format } from 'date-fns';

import AppAmount from 'components/ui-kit/AppAmount/AppAmount';
import { IAdditionalServicesResult, IGetOrderInfoItem } from 'types/types';
import { TimeRange } from 'components/TimeRange/TimeRange';
import { parseApiDate } from 'utils/parseApiDate';
import { useDateLocale } from 'i18n/useDateLocale';

import { Root } from './ServiceSlots.styles';

type TServiceSlots = {
  slots: IGetOrderInfoItem[];
  additionalServices: IAdditionalServicesResult[];
};

export const ServiceSlots = (props: TServiceSlots) => {
  const { slots, additionalServices } = props;
  const locale = useDateLocale();

  return (
    <Root>
      {slots.map(orderSlot => (
        <li className="service-slots__element" key={orderSlot.id}>
          <div className="service-slots__wrap">
            <time className="service-slots__date">
              {format(parseApiDate(orderSlot.from), 'd MMMM yyyy, EEEEEE', {
                locale,
              })}
            </time>
            <p className="service-slots__slot">
              <TimeRange slot={orderSlot} />
            </p>

            <AppAmount
              className="service-slots__total"
              amount={Number(orderSlot.price)}
            />
          </div>

          {orderSlot.ordered_item_service_ids.map(additionalServiceId => {
            const additionalService = additionalServices.find(
              item => item.item_service_id === additionalServiceId,
            );

            if (!additionalService) {
              return null;
            }

            return (
              <div
                className="service-slots__services"
                key={additionalServiceId}>
                <p className="service-slots__service-name">
                  {additionalService.name}
                </p>
                <p className="service-slots__service-price">
                  <AppAmount amount={Number(additionalService.price)} />
                </p>
              </div>
            );
          })}
        </li>
      ))}
    </Root>
  );
};

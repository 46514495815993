import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IRentalItem } from 'types/types';
import AppAmount from 'components/ui-kit/AppAmount/AppAmount';

import { ImgFigure, StyledServiceMiniCard } from './ServiceMiniCard.styles';

interface IServiceMiniCardProps {
  item: IRentalItem;
  label: string;
  showPrice?: boolean;
}

export const ServiceMiniCard = ({ item, label, showPrice = false }: IServiceMiniCardProps) => {
  const { t } = useTranslation();

  return (
    <StyledServiceMiniCard className="service-mini-card">
      <ImgFigure>
        <img src={item.params.image_url} alt={item.name} />
      </ImgFigure>

      <div>
        <Typography
          component="h3"
          color="text.secondary"
          fontSize={16}
          fontWeight={600}
        >
          {item.name}
        </Typography>
        <Typography color="text.tertiary" component="span" fontSize={14}>
          {label}
        </Typography>

        {showPrice && (
          <p className="service-mini-card__amount">
            {t('FROM')} <AppAmount amount={Number(item.price)} />
          </p>          
        )}        
      </div>
    </StyledServiceMiniCard>
  );
};

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'routers/constants';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { useSafeParams } from 'hooks/useSafeParams';
import { usePhone } from 'hooks/usePhone';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';

import WaveAnimation from 'assets/animation/wave-animation.svg?react';
import { ChevronRightIcon } from 'assets/images';

import {
  Brand,
  BrandImg,
  Menu,
  ProjectDescription,
  StyledFirstStep,
} from './FirstStep.styles';
import { useProjectData } from './hooks/useProjectData';
import { useIconsAndLogo } from './hooks/useIconsAndLogo';

export const FirstStep = () => {
  const { t } = useTranslation();

  const { publicId } = useSafeParams();
  const { data, publicParams, isLoadingPublicParams, rentalProjectId } =
    useProjectData(publicId);

  const { setPhone } = usePhone();
  const feedbackPhone = data?.data.project_info.phone;

  useEffect(() => {
    if (feedbackPhone) {
      setPhone(feedbackPhone);
    }
  }, [feedbackPhone]);

  const { icons, logoUrl, baseUrl } = useIconsAndLogo(publicParams);

  const renderSvgIcon = (svgString?: string) => {
    if (!svgString) {
      return null;
    }
    return <span dangerouslySetInnerHTML={{ __html: svgString }} />;
  };

  return (
    <StyledFirstStep>
      <ProjectDescription>
        <Typography
          component="h1"
          fontSize={20}
          fontWeight={600}
          color="text.primary">
          {data?.data.project_info.name}
        </Typography>
        <Typography
          component="h1"
          fontSize={12}
          color="text.primary"
          marginTop="6px">
          {data?.data.project_info.description}
        </Typography>
      </ProjectDescription>
      <Menu role="menu">
        <Link to={Paths.Services} className="menu__element">
          {renderSvgIcon(icons.ICON_1)}
          <Typography
            component="h2"
            fontSize={16}
            fontWeight={600}
            color="text.secondary"
            marginLeft="10px">
            {t('STEP_ONE')}
          </Typography>
          <ChevronRightIcon className="menu__arrow" />
        </Link>
        <Link to={Paths.Slots} className="menu__element">
          {renderSvgIcon(icons.ICON_2)}
          <Typography
            component="h2"
            fontSize={16}
            fontWeight={600}
            color="text.secondary"
            marginLeft="10px">
            {t('SEL_DATETIME')}
          </Typography>
          <ChevronRightIcon className="menu__arrow" />
        </Link>
      </Menu>
      <Brand>
        <BrandImg>
          {!logoUrl && !baseUrl && isLoadingPublicParams ? (
            <AppSpinner />
          ) : (
            <img
              src={`${baseUrl.slice(0, -1)}${logoUrl}`}
              decoding="sync"
              loading="eager"
              alt="Logo company"
            />
          )}
        </BrandImg>
        {rentalProjectId === 1 && <WaveAnimation />}
      </Brand>
    </StyledFirstStep>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppModal, { IAppModalProps } from '../AppModal/AppModal';
import { AppBoxWithShadow } from '../../ui-kit/AppBoxWithShadow';
import AppBtn from '../../ui-kit/AppBtn/AppBtn';

import { StyledAppQrModal } from './AppQrModal.styles';

interface IAppQrModalProps extends Omit<IAppModalProps, 'children'> {
  imgSrc: string;
  imgAlt?: string;
  onQrDownloadBtnClick?: (() => void) | undefined;
  onClose?: () => void;
}

const AppQrModal: FC<IAppQrModalProps> = ({
  imgSrc,
  imgAlt = 'QR код.',
  onQrDownloadBtnClick,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();

  const [displayedImgSrc, setDisplayedImgSrc] = useState('');

  useEffect(() => {
    imgSrc && setDisplayedImgSrc(imgSrc);
  }, [imgSrc]);

  return (
    <AppModal onClose={onClose} {...rest}>
      <StyledAppQrModal className="app-qr-modal">
        <img className="app-qr-modal__img" src={displayedImgSrc} alt={imgAlt} />

        <AppBoxWithShadow className="app-qr-modal__btns-box">
          {onQrDownloadBtnClick && <AppBtn onClick={onQrDownloadBtnClick} fullWidth>{t('DOWNLOAD')}</AppBtn>}
          <AppBtn onClick={onClose} fullWidth>{t('CLOSE')}</AppBtn>
        </AppBoxWithShadow>
      </StyledAppQrModal>
    </AppModal>
  );
};

export default AppQrModal;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledRoot = styled.section(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    padding: 16px;
    text-align: center;

    .cancel-order-drawer__close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: ${theme.palette.text.primary};

      &:hover,
      &:active,
      &:focus {
        color: ${theme.palette.text.primary};
        opacity: 0.5;
      }
    }

    .cancel-order-drawer__icon {
      margin-bottom: 20px;
    }

    .cancel-order-drawer__title {
      margin-bottom: 16px;
    }

    .cancel-order-drawer__text {
      width: 80%;
      text-wrap: balance;
      text-align: center;
    }

    .cancel-order-drawer__control {
      border-radius: 100px;
      padding: 12px 24px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  `,
);

import React, { useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import { publicApi } from 'store/api';
import { useAppSelector } from 'store/hooks';

import { useSafeParams } from 'hooks/useSafeParams';
import { IRentalItem, ITimeSlot } from 'types/types';
import { OrderDetails, StepAction } from 'features/order';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';

import { ServiceCard } from '../ServiceCard';

import { StyledSelectService } from './SelectService.styles';

interface ISelectServiceProps {
  slots: ITimeSlot[];
  onSubmit: (services: IRentalItem[]) => void;
}

export const SelectService = ({ slots, onSubmit }: ISelectServiceProps) => {
  const { t } = useTranslation();

  const { publicId } = useSafeParams();
  const { data, isLoading } = publicApi.useGetUnitedDataQuery(
    publicId
      ? {
          public_id: publicId,
        }
      : skipToken
  );

  const defaultSelected = useAppSelector((state) => state.order.services);
  const [selected, setSelected] = useState<IRentalItem[]>(defaultSelected);

  const items = useMemo(() => {
    return (
      data?.data.rental_items.filter((item) =>
        slots.every((slot) => slot.available_items.includes(item.id))
      ) ?? []
    );
  }, [data?.data.rental_items, slots]);

  return (
    <StyledSelectService className="selectService">
      <div className="selectService__items">
        {isLoading ? (
          <AppSpinner />
        ) : (
          items.map((item) => (
            <ServiceCard
              key={item.id}
              item={item}
              onSelect={(checked) => {
                setSelected((prevState) => {
                  if (checked) {
                    return [...prevState, item];
                  } else {
                    return [...prevState.filter((prev) => prev !== item)];
                  }
                });
              }}
              selected={selected.includes(item)}
            />
          ))
        )}
      </div>
      <StepAction
        disabled={selected.length === 0}
        onSubmit={() => onSubmit(selected)}
        label={t('NEXT')}
      >
        {slots.length > 0 ? (
          <OrderDetails
            services={selected}
            slots={slots}
            additionalServiceOrders={[]}
          />
        ) : null}
      </StepAction>
    </StyledSelectService>
  );
};

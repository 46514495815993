import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { joinClasses } from 'utils/joinClasses';
import AppAmount from 'components/ui-kit/AppAmount/AppAmount';

import { StyledResultPrice } from './ResultPrice.styles';

type TResulPrice = {
  className?: string;
  totalCost: number;
};

export const ResultPrice = (props: TResulPrice) => {
  const { className, totalCost } = props;
  const { t } = useTranslation();

  return (
    <StyledResultPrice
      className={joinClasses(
        className,
        'result-step__result-price result-price'
      )}
    >
      <div className="result-price__wrapper">
        <Typography
          className="result-price__total"
          component="h2"
          fontSize={16}
          fontWeight={600}
          lineHeight={1.3}
          color="text.secondary"
        >
          {t('TOTAL_COST')}
        </Typography>

        <div className="result-price__dotted"></div>

        <AppAmount amount={totalCost} className="result-price__amount" />
      </div>
    </StyledResultPrice>
  );
};

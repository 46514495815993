import { skipToken } from '@reduxjs/toolkit/query';

import { publicApi } from 'store/api';

export const useProjectData = (publicId?: string) => {
  const { data } = publicApi.useGetUnitedDataQuery(
    publicId ? { public_id: publicId } : skipToken,
  );
  const rentalProjectId = Number(data?.data.project_info.id);

  const { data: publicParams, isLoading: isLoadingPublicParams } =
    publicApi.useGetPublicParamsQuery(
      publicId && rentalProjectId
        ? { public_id: publicId, rental_project_id: rentalProjectId }
        : skipToken,
    );

  return { data, publicParams, isLoadingPublicParams, rentalProjectId };
};

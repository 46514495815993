import styled from '@emotion/styled';

export const StyledAppCustomModal = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
  overflow: hidden;

  .app-custom-modal {
    &__window {
      position: absolute;
      background: inherit;
      z-index: 2;
      width: 100%;
      height: 100%;
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AppBoxWithShadow = styled.div(
  ({ theme }) => css`
    padding: 20px;
    background-color: ${theme.palette.background.secondary};
    box-shadow: ${theme.shadows[1]};
    border-radius: 10px;
  `
);

import React, { ButtonHTMLAttributes, FC, memo, SVGProps } from 'react';

import { joinClasses } from 'utils/joinClasses';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';

import { StyledAppBtn } from './AppBtn.styles';

export interface IAppBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  filling?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'filled'
    | 'border'
    | 'none'
    | 'ascetic';
  LeftIcon?: FC<SVGProps<SVGSVGElement>>;
  RightIcon?: FC<SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
  iconHeight?: number;
  fullWidth?: boolean;
  size?: 's' | 'm' | 'l';
}

const AppBtn: FC<IAppBtnProps> = ({
  children,
  filling = 'primary',
  LeftIcon,
  RightIcon,
  className,
  isLoading,
  disabled,
  iconHeight,
  fullWidth,
  size = 'm',
  ...rest
}) => {
  return (
    <StyledAppBtn
      className={joinClasses('app-btn', className)}
      type="button"
      filling={filling}
      disabled={disabled || isLoading}
      iconHeight={iconHeight}
      fullWidth={fullWidth}
      size={size}
      {...rest}>
      {isLoading && <AppSpinner className="app-btn__spinner" size={22} />}

      {LeftIcon && (
        <LeftIcon className="app-btn__icon app-btn__icon_position_left" />
      )}
      {children}
      {RightIcon && (
        <RightIcon className="app-btn__icon app-btn__icon_position_right" />
      )}
    </StyledAppBtn>
  );
};

export default memo(AppBtn);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AppModalText = styled.p(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.text.secondary};
    text-align: center;
    text-wrap: balance;
    margin-bottom: 20px;
  `
);

import { useState } from 'react';

interface IPhone {
  number: string;
  whatsAppLink: string;
  callLink: string;
}

const initialPhone: IPhone = {
  number: '',
  whatsAppLink: '',
  callLink: '',
};

export const usePhone = () => {
  const [phone, setPhoneState] = useState<IPhone>(initialPhone);

  const setPhone = (feedbackPhone: string) => {
    const formattedNumber = feedbackPhone
      .replace(/^(\+?)/, '$1')
      .replace(/[()\s-]/g, '');

    const newPhone: IPhone = {
      number: feedbackPhone,
      whatsAppLink: `https://wa.me/${formattedNumber}`,
      callLink: `tel:${formattedNumber}`,
    };

    setPhoneState(newPhone);
    return newPhone;
  };

  return { phone, setPhone };
};

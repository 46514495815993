import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useScrollbarStyles } from 'hooks/useScrollbarStyles';
import { AppBoxWithShadow } from 'components/ui-kit/AppBoxWithShadow';

export const AppModalContentBox = styled(AppBoxWithShadow)(
  ({ theme }) => css`
    padding: 40px 100px;
    display: grid;
    justify-content: center;
    align-content: stretch;
    overflow: auto;
    max-height: calc(100vh - 32px);
    max-height: calc(100dvh - 32px);
    transition: height ${theme.transitions.duration.shortest}ms ease-out;

    ${useScrollbarStyles('wide')};

    @media (max-width: ${theme.breakpoints.values.xs}px) {
      padding: 20px;
    }
  `
);

import { Global, css, useTheme } from '@emotion/react';
import React from 'react';

import { useScrollbarStyles } from 'hooks/useScrollbarStyles';

const GlobalStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        html,
        body {
          min-height: 100%;
        }

        body {
          background-color: ${theme.palette.background.neutral};
          font-family: ${theme.typography.fontFamily};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          margin: 0;
          padding: 0;
          overflow-x: hidden;
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        span,
        input,
        button,
        a,
        label {
          line-height: 1.3;
        }

        input::placeholder {
          transition: opacity ${theme.transitions.duration.shortest}ms ease-out;
        }

        input:focus-visible::placeholder {
          opacity: 0.5;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }

        #root {
          display: flex;
          flex-direction: column;
          max-width: 600px;
          width: 100%;
          min-height: 100svh;

          @supports (height: 100dvh) {
            min-height: 100dvh;
          }

          margin: 0 auto;
          background-color: ${theme.palette.background.default};
        }

        *:focus-visible {
          outline: 1px ${theme.palette.primary.main} solid;
          outline-offset: 3px;
        }

        :root {
          ${useScrollbarStyles('wide')}
        }

        .visually-hidden {
          position: absolute;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
          clip: rect(0, 0, 0, 0);
          overflow: hidden;
        }
      `}
    />
  );
};

export default GlobalStyles;

import React from 'react';
import i18n from 'i18next';

import { joinClasses } from 'utils/joinClasses';

import { TelegramIcon, ViberIcon, VkIcon, WhatsAppIcon } from 'assets/images';

import { StyledSocial } from './ShareSocial.styles';

type TSocialType = 'tg' | 'vk' | 'viber' | 'whatsApp';

interface IShareProps {
  className?: string;
  url: string;
  text?: string;
  socials: TSocialType[];
}

const getSocialInfo = (social: TSocialType, url: string, text: string = '') => {
  switch (social) {
  case 'tg':
    return {
      label: i18n.t('SHARE_ON', {social: 'Telegram'}),
      icon: <TelegramIcon />,
      href: `https://telegram.me/share/url?text=${text}&url=${url}`,
    };
  case 'vk':
    return {
      label: i18n.t('SHARE_ON', {social: 'VK'}),
      icon: <VkIcon />,
      href: `https://vk.com/share.php?title=${text}&url=${url}`,
    };
  case 'viber':
    return {
      label: i18n.t('SHARE_ON', {social: 'Viber'}),
      icon: <ViberIcon />,
      href: `viber://forward?text=${text} ${url}`,
    };
case 'whatsApp':
    return {
      label: i18n.t('SHARE_ON', {social: 'WhatsApp'}),
      icon: <WhatsAppIcon />,
      href: `https://wa.me/?text=${text} ${url}`,
    };
  }
};

const ShareSocial = (props: IShareProps) => {
  const { className, url, socials, text } = props;

  return (
    <StyledSocial className={joinClasses('share-social', className)}>
      {socials.map((social, index) => {
        const { href, icon, label } = getSocialInfo(social, url, text);

        return (
          <li key={index} className="share-social__element">
            <a className="share-social__link" target="_blank" key={social} aria-label={label} href={href} rel="noreferrer">
              {icon}
            </a>
          </li>
        );
      })}
    </StyledSocial>
  );
};

export default ShareSocial;

import React from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';

import { useDateLocale } from 'i18n/useDateLocale';

import { Root } from './ServiceBookDate.styles';

interface IServiceBookDateProps {
  className?: string;
  minDate: Date;
}

export const ServiceBookDate = (props: IServiceBookDateProps) => {
  const { className, minDate } = props;
  const locale = useDateLocale();

  return (
    <Root className={className}>
      <Typography
        component="span"
        color="text.secondary"
        fontSize={48}
        fontWeight={600}
        lineHeight={0.7}
      >
        {format(minDate, 'dd', { locale })}
      </Typography>

      <div className="service-book-date__right">
        <Typography
          className="service-book-date__month"
          component="span"
          color="text.secondary"
          fontSize={20}
          fontWeight={600}
          lineHeight={1}
        >
          {' '}
          {format(minDate, 'MMMM', { locale })}
        </Typography>

        <Typography
          className="service-book-date__start-time"
          component="span"
          color="text.secondary"
        >
          {format(minDate, 'EEEE,', { locale })}
          <time>
            {' c '}
            {format(minDate, 'HH:mm', { locale })}
          </time>
        </Typography>
      </div>
    </Root>
  );
};

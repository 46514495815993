import React, { useEffect, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';

import { useAppSelector } from 'store/hooks';
import { getOrderItems } from 'store/slices/order';
import { publicApi } from 'store/api';

import AppInput from 'components/ui-kit/AppInput/AppInput';
import AppSwitch from 'components/ui-kit/AppSwitch/AppSwitch';
import { AppSelect, ISelectOption } from 'components/app-selects';
import { usePhoneMask } from 'hooks/usePhoneMask';
import { AppTextField } from 'components/ui-kit/AppTextField';
import { ServiceBookDate } from 'features/resultStep/ServiceBookDate';
import { useSafeParams } from 'hooks/useSafeParams';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { ResultPrice } from 'features/resultStep/ResultPrice';
import { AppBoxWithShadow } from 'components/ui-kit/AppBoxWithShadow';
import { parseApiDate } from 'utils/parseApiDate';

import { OrderTimeDetails } from '../OrderTimeDetails';

import { TClientInformation } from './schema';
import {
  OrderInfo,
  Remind,
  RemindTime,
  ResultPriceStyled,
  StyledForm,
} from './ClientForm.styles';

interface IClientFormProps {
  className?: string;
  paymentTypes: ISelectOption[];
}

export const ClientForm = ({ className, paymentTypes }: IClientFormProps) => {
  const { register, watch, setValue, formState, control } =
    useFormContext<TClientInformation>();

  const { t } = useTranslation();

  const { onInputMaskPhone, onPhonePaste, maxLength } = usePhoneMask();

  const remindVariants: ISelectOption[] = useMemo(
    () => [
      { value: '2', label: t('REMIND_HOUR', { count: 2 }) },
      { value: '12', label: t('REMIND_HOUR', { count: 12 }) },
      { value: '24', label: t('REMIND_HOUR', { count: 24 }) },
    ],
    [t],
  );

  const { services, slots, additionalServiceOrders } = useAppSelector(
    state => state.order,
  );
  const { publicId } = useSafeParams();

  const {
    data: totalSetPrice,
    isFetching: priceFetching,
    isError: priceError,
  } = publicApi.useGetTotalSetPriceQuery(
    publicId && slots.length > 0 && services.length > 0
      ? {
          public_id: publicId,
          orders: getOrderItems({
            services,
            slots,
            additionalServiceOrders,
          }),
        }
      : skipToken,
  );

  const orderMinDate = useMemo(() => {
    return parseApiDate(slots[0].from);
  }, [slots]);

  return (
    <StyledForm className={className}>
      <AppInput
        label={t('NAME')}
        value={watch('first_name')}
        required
        {...register('first_name')}
        errText={formState.errors.first_name?.message}
        autoComplete="given-name"
      />

      <Controller
        control={control}
        name="phone"
        render={({ field: { onChange, value } }) => (
          <AppInput
            label={t('ENTER_PHONE')}
            name="phone"
            onChange={onChange}
            onInput={onInputMaskPhone}
            onPaste={onPhonePaste}
            maxLength={maxLength}
            value={value}
            required
            errText={formState.errors.phone?.message}
            autoComplete="tel"
          />
        )}
      />
      <div>
        <AppInput
          label={t('ENTER_EMAIL')}
          type="email"
          value={watch('email')}
          {...register('email')}
          errText={formState.errors.email?.message}
          autoComplete="email"
        />
        <Typography
          className="help-text"
          component="small"
          fontSize={12}
          fontWeight={500}
          lineHeight={1.3}
          color="text.tertiary"
          marginBottom="11px">
          {t('EMAIL_HELP_TEXT')}
        </Typography>
      </div>
      <Controller
        control={control}
        name="comment"
        render={({ field: { onChange, value } }) => (
          <AppTextField
            className="form__field"
            variant="outlined"
            label={t('COMMENT_PLACEHOLDER')}
            value={value}
            multiline
            maxRows={5}
            inputProps={{
              maxLength: 325,
            }}
            name="comment"
            onChange={onChange}
            helperText=" "
          />
        )}
      />

      <Remind>
        <RemindTime>
          <Typography fontSize={14} fontWeight={500} color="text.primary">
            {t('REMIND')}
          </Typography>
          <Typography fontSize={14} fontWeight={400} color="text.tertiary">
            {t('REMIND_FOR')}
          </Typography>

          <Controller
            control={control}
            name="remindTime"
            render={({ field: { onChange, value } }) => (
              <AppSelect
                className="app-select__form-small"
                options={remindVariants}
                onChange={value => {
                  setValue('remind', true);
                  onChange(value);
                }}
                value={value ?? null}
                menuPlacement="bottom"
              />
            )}
          />
        </RemindTime>

        <AppSwitch {...register('remind')} height={30} />
      </Remind>
      <OrderInfo>
        <ServiceBookDate minDate={orderMinDate} />
        <OrderTimeDetails
          className="order-info__time-details"
          services={services}
          slots={slots}
          additionalServiceOrders={additionalServiceOrders}
        />
      </OrderInfo>

      <ResultPriceStyled>
        {priceFetching ? (
          <span>
            <AppSpinner size={20} />
          </span>
        ) : totalSetPrice && !priceError ? (
          <ResultPrice totalCost={totalSetPrice.data.result.total_set_price} />
        ) : null}
      </ResultPriceStyled>

      <AppBoxWithShadow>
        <Typography fontSize={14} fontWeight={600} color="text.secondary">
          {t('PAYMENT_VARIANT')}
        </Typography>
        <Controller
          control={control}
          name="payment_type"
          render={({ field: { onChange, value } }) => (
            <AppSelect
              className="app-select__payment-method"
              options={paymentTypes}
              onChange={onChange}
              value={value ?? null}
              menuPlacement="bottom"
              isSearchable={false}
            />
          )}
        />
      </AppBoxWithShadow>
    </StyledForm>
  );
};

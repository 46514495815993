import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner.tsx';

import './i18n';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import routers from './routers';
import { store } from './store';
import GlobalStyles from './styles/GlobalStyles';
import ToastStyles from './styles/ToastStyles';
import { theme } from './styles/theme';

import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastStyles />

      <Suspense fallback={<AppSpinner />}>
        <Provider store={store}>
          <RouterProvider router={routers} />
        </Provider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>
);

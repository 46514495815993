import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledHeader = styled.header(
  ({ theme }) => css`
    position: sticky;
    z-index: 100;
    top: 0;
    display: flex;
    background-color: ${theme.palette.background.primary};
    align-items: center;
    font-size: 14px;
    color: ${theme.palette.text.primary};
    padding: 6px 16px;
    border-bottom: 1px solid ${theme.palette.background.line};

    .header {
      &__back {
        color: ${theme.palette.primary.main};
      }

      &__action {
        margin-left: auto;

        button {
          max-height: 24px;
        }
      }

      &__lang {
        visibility: hidden;

        svg {
          border-radius: 6px;
          border: 1px solid ${theme.palette.background.line};
        }
      }

      &__icon,
      &__icon:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  `,
);

import { useEffect, useState } from 'react';

import { IApiOnePageResponse } from 'types/general';
import { IPublicParamsResponse } from 'types/types';

interface IIcons {
  ICON_1?: string;
  ICON_2?: string;
  ICON_3?: string;
  [key: string]: string | undefined;
}

export const useIconsAndLogo = (
  publicParams: IApiOnePageResponse<IPublicParamsResponse> | undefined,
): {
  icons: IIcons;
  logoUrl: string;
  baseUrl: string;
} => {
  const [result, setResult] = useState<{
    icons: IIcons;
    logoUrl: string;
    baseUrl: string;
  }>({
    icons: {},
    logoUrl: '',
    baseUrl: '',
  });

  useEffect(() => {
    const { data } = publicParams || {};
    const { sections } = data?.result || {};
    const { fields } = sections?.[0] || {};

    const newIcons: IIcons = {};
    let newLogoUrl = '';
    let newBaseUrl = '';

    fields?.forEach(field => {
      if (field.name.startsWith('ICON_')) {
        newIcons[field.name] = field.value;
      } else if (field.name === 'BASE_URL') {
        newBaseUrl = field.value;
      } else if (field.name === 'LOGO_URL') {
        newLogoUrl = field.value;
      }
    });

    setResult({
      icons: newIcons,
      logoUrl: newLogoUrl,
      baseUrl: newBaseUrl,
    });
  }, [publicParams]);

  return result;
};

import React, { FC, SVGProps } from 'react';
import { LinkProps } from 'react-router-dom';

import { joinClasses } from 'utils/joinClasses';

import { StyledAppLink } from './AppLink.styles';

interface IAppLinkProps extends LinkProps {
  LeftIcon?: FC<SVGProps<SVGSVGElement>>;
  RightIcon?: FC<SVGProps<SVGSVGElement>>;
}

const AppLink: FC<IAppLinkProps> = ({
  children,
  LeftIcon,
  RightIcon,
  className,
  ...rest
}) => {
  return (
    <StyledAppLink className={joinClasses('app-link', className)} {...rest}>
      {LeftIcon && (
        <LeftIcon className="app-link__icon app-link__icon_position_left" />
      )}
      {children}
      {RightIcon && (
        <RightIcon className="app-link__icon app-link__icon_position_right" />
      )}
    </StyledAppLink>
  );
};

export default AppLink;

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { joinClasses } from 'utils/joinClasses';

import { StyledAppCustomModal } from './AppCustomModal.style';

type TProps = {
  children: React.ReactNode;
  isOpen: boolean;
  isCloseOnOverlayClick?: boolean;
  className?: string;
  onClose: () => void;
};

const AppCustomModal = (props: TProps) => {
  const {
    children,
    isOpen,
    isCloseOnOverlayClick = false,
    className,
    onClose: onCloseModal,
  } = props;

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <StyledAppCustomModal
      className={joinClasses('app-custom-modal', className)}>
      <div className="app-custom-modal__window">
        <div
          className="app-custom-modal__overlay"
          onClick={isCloseOnOverlayClick ? onCloseModal : undefined}
        />
        {children}
      </div>
    </StyledAppCustomModal>,
    document.body,
  );
};

export default AppCustomModal;

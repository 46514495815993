import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledResultService = styled.section(
  ({ theme }) => css`
    .result-step__wrap {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      align-items: baseline;
    }

    .result-service__date {
      display: flex;
      justify-content: space-between;
      align-items: start;
      padding: 0 14px 9px;
      border-bottom: 1px solid ${theme.palette.background.line};
      margin-bottom: 20px;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }

    .result-service__time {
      display: grid;
      grid-template-columns: auto auto;
      gap: 0 8px;
    }

    .result-service__number {
      grid-row: 1/3;
    }

    .result-service__month {
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .result-service__start-time {
      font-weight: inherit;
    }

    .result-service__geolocation {
      padding: 0;
      height: auto;

      border-radius: 50%;
      background-color: ${theme.palette.background.neutral};
    }

    .result-slots {
      margin-top: 10px;
      display: grid;
      gap: 10px;
    }

    .result-slots__list {
      margin: 0;
      padding: 0;
      list-style: none;

      display: grid;
      gap: 10px;
    }

    .result-slots__element {
      display: grid;
      gap: 10px;

      padding: 14px;
      border-radius: 10px;
      border: 1px solid ${theme.palette.background.line};
      text-align: center;
    }

    .result-slots__wrap {
      display: grid;
      grid-template-columns: auto auto;
      gap: 4px;
      grid-template-rows: auto auto;
      justify-content: space-between;
      line-height: 1.3;
    }

    .result-slots__date {
      font-size: 14px;
      text-align: left;
      font-weight: 500;
      color: ${theme.palette.text.tertiary};
    }

    .result-slots__slot {
      margin: 0;

      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: ${theme.palette.text.secondary};
    }

    .result-slots__time {
      font-weight: 500;
    }

    .result-slots__total {
      margin: 0;
      grid-column: 2 / 3;
      grid-row: 1/-1;
      justify-self: right;
      align-self: center;

      font-size: 16px;
      font-weight: 600;
      color: ${theme.palette.text.secondary};
    }

    .result-slots__services {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      color: ${theme.palette.text.secondary};

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    .result-slots__service-name {
      margin: 0;
    }

    .result-slots__service-price {
      margin: 0;
      font-weight: 600;
    }
  `,
);

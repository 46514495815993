import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledServiceCard = styled.label<{
  selected: boolean;
}>(({ theme, selected }) => {
  const getStateStyles = () => {
    if (selected) {
      return css`
        outline: 1px solid ${theme.palette.text.accent};
      `;
    }
  };

  return css`
    position: relative;
    cursor: pointer;
    display: grid;
    gap: 10px;
    padding: 14px;
    box-shadow: ${theme.shadows[24]};
    background: ${theme.palette.background.primary};
    border-radius: 10px;

    ${getStateStyles()}

    p {
      margin: 0;
    }

    .control-checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .serviceCard {
      &__wrapper {
        display: flex;
        justify-content: space-between;

        &__title {
          display: grid;
          gap: 5px;
        }
      }

      &__img {
        margin: 0 auto;
        width: 250px;
        height: 100px;
        text-align: center;

        img {
          max-width: 250px;
          max-height: 100px;
        }
      }
    }
  `;
});

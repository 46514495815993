import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';

import { useForm } from 'hooks/useForm';
import { IPaymentType } from 'types/types';
import { ISelectOption } from 'components/app-selects';

import { StepAction } from '../StepAction';
import {
  ClientForm,
  ClientInformationSchema,
  TClientInformation,
} from '../ClientForm';

import { StyledClientInformation } from './ClientInformation.styles';

interface IClientInformationProps {
  paymentTypes: IPaymentType[];
  onSubmit: (values: TClientInformation) => Promise<unknown>;
}

export const ClientInformation = ({
  paymentTypes,
  onSubmit,
}: IClientInformationProps) => {
  const { t } = useTranslation();

  const paymentTypeVariants: ISelectOption[] = useMemo(
    () =>
      paymentTypes.map(item => ({
        value: item.id ?? '',
        label: item.name,
      })),
    [paymentTypes],
  );

  const formMethods = useForm<TClientInformation>({
    schema: ClientInformationSchema,
    defaultValues: {
      payment_type:
        paymentTypeVariants.length > 0
          ? paymentTypeVariants.find(item => item.label === 'Онлайн') ||
            paymentTypeVariants[0]
          : undefined,
      remind: true,
      remindTime: { value: '2', label: t('REMIND_HOUR', { count: 2 }) },
      notification: {
        email: true,
        sms: false,
      },
    },
  });

  return (
    <StyledClientInformation
      onSubmit={formMethods.handleSubmit(onSubmit)}
      autoComplete="on">
      <FormProvider {...formMethods}>
        <ClientForm
          className="client-form"
          paymentTypes={paymentTypeVariants}
        />
      </FormProvider>
      <StepAction
        isLoading={formMethods.formState.isSubmitting}
        type="submit"
        label={t('BOOK')}
      />
    </StyledClientInformation>
  );
};

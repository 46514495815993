const copyToClipboard = async (text: string, onSuccess?: VoidFunction, onError?: VoidFunction) => {
    try {
        await navigator.clipboard.writeText(text);

        onSuccess?.();
    } catch (_) {
        onError?.();
    }
};

export default copyToClipboard;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledPaymentStatus = styled.div(
  ({ theme }) => css`
    position: relative;

    .payment-status__text {
      display: inline-block;
      border-radius: 8px;
      padding: 2px 6px;
      color: ${theme.palette.text.invertedPrimary};
    }

    .payment-status--paid {
      background-color: ${theme.palette.success.main};
    }

    .payment-status--no-paid {
      background-color: ${theme.palette.error.main};
    }
  `
);

import { useSearchParams } from 'react-router-dom';

export const useQuickBookSearchParams = () => {
  const [searchParams] = useSearchParams();

  return {
    first_name: searchParams.get('first_name'),
    from: searchParams.get('from'), // '2024-01-01 10:00:00'
    to: searchParams.get('to'), // '2024-01-01 10:00:00'
    id: searchParams.get('id'),
    is_paid: searchParams.get('is_paid'), // 0
    name: searchParams.get('name'),
    new: searchParams.get('new'), // 1
    phone: searchParams.get('phone'),
    rental_item_id: searchParams.get('rental_item_id'),
    comment: searchParams.get('comment'),
    email: searchParams.get('email'),
  };
};

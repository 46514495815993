import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledSelectService = styled.section(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;

    .selectService {
      &__items {
        display: grid;
        grid-template-columns: 1fr;
        align-items: start;
        justify-content: start;
        gap: 20px;
        padding: 16px 16px 40px;

        @media (min-width: ${theme.breakpoints.values.sm}px) {
          padding: 20px 20px 40px;
        }
      }
    }
  `
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledStepAction = styled.div(
  ({ theme }) => css`
    position: sticky;
    z-index: 100;
    bottom: 0;
    display: grid;
    grid-auto-flow: column;
    gap: 14px;
    align-items: normal;
    margin-top: auto;
    padding: 16px 16px 30px;
    background-color: ${theme.palette.background.default};
    box-shadow: ${theme.shadows[23]};
  `,
);

import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { AppFade } from 'components/app-transitions';

const BackdropUnstyled = forwardRef<HTMLDivElement, { open?: boolean }>(
  (props, ref) => {
    const { open, ...rest } = props as any;

    return (
      <AppFade in={open}>
        <div {...rest} ref={ref} />
      </AppFade>
    );
  }
);

BackdropUnstyled.displayName = 'BackdropUnstyled';

export const AppModalBackdrop = styled(BackdropUnstyled)(
  ({ theme }) => css`
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.palette.background.overlay};
    -webkit-tap-highlight-color: transparent;
  `
);

import React, { FC } from 'react';
import { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';
import { useTheme } from '@mui/material';

import { StyledAppDrawer } from './AppDrawer.styles';

export interface IAppDrawerProps extends Omit<SwipeableDrawerProps, 'onOpen'> {}

const AppDrawer: FC<IAppDrawerProps> = ({
  children,
  anchor,
  onClose,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <StyledAppDrawer
      transitionDuration={theme.transitions.duration.shortest}
      anchor={anchor}
      onOpen={() => {}}
      onClose={onClose}
      {...rest}
      closeAfterTransition
    >
      {children}
    </StyledAppDrawer>
  );
};

export default AppDrawer;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledAppQrModal = styled.div(
  () => css`
    display: grid;
    row-gap: 20px;
    max-width: 340px;
    width: 100%;

    .app-qr-modal {
      &__img {
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 10px;
      }

      &__btns-box {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        column-gap: 10px;
      }
    }
  `
);

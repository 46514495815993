import React from 'react';
import { Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppSelect, ISelectOption } from 'components/app-selects';
import { AppBoxWithShadow } from 'components/ui-kit/AppBoxWithShadow';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { IResultStepOrderItem } from 'features/resultStep/types';

import { TClientInformationInAdmin } from '../ClientForm';
import { SharedLinks } from '../SharedLinks';

import { Root } from './PaymentTypeForm.style';

interface IPaymentTypeFormProps {
  orders: IResultStepOrderItem[];
  orderInfoLoading: boolean;
  paymentPageUrl: string;
  paymentTypes: ISelectOption[];
}

export const PaymentTypeForm = ({
  orders,
  orderInfoLoading,
  paymentPageUrl,
  paymentTypes,
}: IPaymentTypeFormProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext<TClientInformationInAdmin>();

  return (
    <AppBoxWithShadow>
      <Root>
        {/* Блок скрыт, для избежания дублирования, потом вернём если потребует клиент */}
        <div className="payment-type-form__wrapper">
          <div>
            <Typography fontSize={14} fontWeight={600} color="text.secondary">
              {t('PAYMENT_VARIANT')}
            </Typography>{' '}
            <Controller
              control={control}
              name="payment_type"
              render={({ field: { onChange, value } }) => (
                <AppSelect
                  options={paymentTypes}
                  onChange={onChange}
                  value={value ?? null}
                  isSearchable={false}
                  menuPlacement="top"
                />
              )}
            />
          </div>

          <Typography
            component="small"
            fontSize={12}
            fontWeight={500}
            color="info.main"
            marginBottom="30px">
            Можно оплатить в любое время до вашей брони
          </Typography>
        </div>

        {orderInfoLoading ? (
          <AppSpinner />
        ) : (
          <SharedLinks paymentPageUrl={paymentPageUrl} orders={orders} />
        )}
      </Root>
    </AppBoxWithShadow>
  );
};

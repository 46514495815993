import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Collapse, CollapseProps, useTheme } from '@mui/material';

const AppCollapse: ForwardRefRenderFunction<unknown, CollapseProps> = (
  props,
  ref
) => {
  const { children, ...rest } = props;
  const theme = useTheme();

  return (
    <Collapse timeout={theme.transitions.duration.shortest} {...rest} ref={ref}>
      {children}
    </Collapse>
  );
};

export default forwardRef(AppCollapse);

import  React, { FC } from 'react';
import { ToastContainer, Slide } from 'react-toastify';

const AppToast: FC = () => {
  return (
    <ToastContainer
      transition={Slide}
      theme="colored"
      hideProgressBar
      autoClose={3000}
    />
  );
};

export default AppToast;

import { isAdminRoute } from 'features/admin/utils/isAdmin';
import { IPaymentType, ITimeSlot } from 'types/types';

export const getPaymentTypes = (
  paymentTypes: IPaymentType[],
  selectedSlots: ITimeSlot[]
) => {
  const PREPAYMENT_NAME = 'Оплата на месте';

  const isPrepaids = selectedSlots
    .map((item) => item.prepaid)
    .some((item) => item === true);

  const onlinePaymentTypes = paymentTypes.filter(
    (item) => item.map === 'online'
  );
  const prepaymentTypes = paymentTypes.filter(
    (item) => item.name === PREPAYMENT_NAME
  );
  const isSinglePaymentType = paymentTypes.length === 1;

  // При условии хотя бы одного выходного сета
  if (isPrepaids) {
    if (isAdminRoute()) {
      // Админ нерабочие дни
      return onlinePaymentTypes.concat(prepaymentTypes);
    } else {
      // Пользователь нерабочие дни

      // один способ оплаты
      if (isSinglePaymentType) {
        return prepaymentTypes;
      } else {
        return onlinePaymentTypes; 
      }
    }
  } else {
    if (isAdminRoute()) {
      // Админ будни
      return paymentTypes;
    } else {
      // Пользователь будни
      return paymentTypes;
    }
  }
};

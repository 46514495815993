import React, { FC, useEffect, useState } from 'react';
import { ActionMeta } from 'react-select';
import Select from 'react-select';

import { joinClasses } from 'utils/joinClasses';
import { IClassName } from 'types/general';
import { ISelectOption } from 'components/app-selects';
import { DropdownIndicator } from 'components/app-selects/DropdownIndicator';
import { LoadingIndicator } from 'components/app-selects/LoadingIndicator';

import { StyledAppSelect } from './AppSelect.styles';

export interface IAppSelectProps extends IClassName {
  options: ISelectOption[];
  label?: string;
  placeholder?: string;
  noOptionsMessage?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  errCssMinHeight?: string;
  errText?: string;
  isValid?: boolean;
  required?: boolean;
  value: ISelectOption | null;
  defaultValue?: ISelectOption;
  onChange: (
    option: ISelectOption | null,
    actionMeta?: ActionMeta<ISelectOption>,
  ) => void;
  menuPlacement?: 'bottom' | 'top' | 'auto';
  size?: 's' | 'm';
}

const AppSelect: FC<IAppSelectProps> = ({
  options,
  className,
  label,
  placeholder = '',
  noOptionsMessage = 'Ничего не найдено',
  isDisabled,
  isLoading,
  isSearchable = true,
  errCssMinHeight,
  errText,
  isValid = true,
  required,
  value,
  defaultValue,
  onChange,
  menuPlacement,
}) => {
  const [isLabelRaised, setIsLabelRaised] = useState(false);

  useEffect(() => {
    if (value) {
      setIsLabelRaised(true);
    }
  }, [value]);

  const handleMenuOpen = () => {
    setIsLabelRaised(true);
  };

  const handleMenuClose = () => {
    !value && setIsLabelRaised(false);
  };

  return (
    <StyledAppSelect
      className={joinClasses('app-select', className)}
      isLabelRaised={isLabelRaised}
      isDisabled={isDisabled}
      errCssMinHeight={errCssMinHeight}
      errText={errText}
      isValid={isValid}>
      <div className="app-select__select-box">
        {label && (
          <span className="app-select__label">
            {required ? label + ' *' : label}
          </span>
        )}

        <Select
          className="app-select__select"
          classNamePrefix="app-select"
          options={options}
          isClearable={false}
          placeholder={placeholder}
          defaultValue={defaultValue || null}
          noOptionsMessage={() => noOptionsMessage}
          components={{ DropdownIndicator, LoadingIndicator }}
          onChange={(newValue, actionMeta) =>
            onChange(
              newValue as ISelectOption,
              actionMeta as ActionMeta<ISelectOption>,
            )
          }
          menuPlacement={menuPlacement}
          value={value || null}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          isDisabled={isDisabled || isLoading}
          isLoading={isLoading}
          isSearchable={isSearchable}
        />
      </div>

      <p className="app-select__err">{errText}</p>
    </StyledAppSelect>
  );
};

export default AppSelect;

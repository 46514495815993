import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Select from 'react-select';

import { useAppSelectStyles } from 'components/app-selects/useAppSelectStyles';

export const StyledAppMultiSelect = styled(Select)(
  ({ theme }) => css`
    .app-multi-select {
      ${useAppSelectStyles()}
    }
  `
);

import { z } from 'zod';

export interface ISelectOption {
  value: string;
  label: string;
  // map: string;
}

export const SelectOptionSchema = z.object({
  value: z.string(),
  label: z.string(),
  // map: z.string(),
});

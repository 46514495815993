import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    padding: 0 16px;
    position: absolute;
    bottom: 94px;
    left: 0;
    right: 0;
    z-index: 101;

    .booking-error-modal {
      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-radius: 14px;
        background: ${theme.palette.text.primary};
        width: 100%;
        margin: 0 auto;
        max-width: 600px;
      }

      &__title-wrapper {
        display: grid;
        gap: 10px;
        grid-template-columns: min-content 1fr min-content;
        align-items: start;
        margin-bottom: 10px;
      }

      &__close-btn {
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
      }

      &__btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      &__link {
        color: ${theme.palette.text.primary};
        padding: 11px 31.5px;
        border-radius: 100px;
        background: ${theme.palette.background.default};
        text-decoration: none;
        column-gap: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18.2px;

        :first-of-type {
          margin-right: 6px;
        }

        :last-of-type {
          padding: 11px 26.5px;
        }

        & > svg {
          min-width: 18px;
          max-width: 18px;
          height: 18px;
        }
      }
    }
  `,
);

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { IApiErr } from 'types/general';

export const isApiError = (value: unknown): value is IApiErr => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'error' in value &&
    typeof (value.error as any).message === 'string'
  );
};

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    'data' in error
  );
}

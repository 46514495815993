import { css, useTheme } from '@emotion/react';

import { checkIsMobileDevice } from 'utils/checkIsMobileDevice';

type TScrollWidth = 'wide' | 'thin';

export const useScrollbarStyles = (width: TScrollWidth = 'thin') => {
  const theme = useTheme();

  const widthPx = checkIsMobileDevice() || width === 'thin' ? 5 : 10;

  return css`
    scrollbar-width: thin;
    scrollbar-color: ${`${theme.palette.primary.light} ${theme.palette.background.neutral}`};

    &::-webkit-scrollbar {
      width: ${widthPx}px;
      height: ${widthPx}px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.palette.background.neutral};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: ${theme.palette.controls.dark};

      &:hover {
        background-color: ${theme.palette.primary.light};
      }
    }
  `;
};

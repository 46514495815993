import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_AUTH } from 'constants/environment';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_AUTH }),
  endpoints: (builder) => ({
    checkTokenAlive: builder.mutation({
      query: (params) => ({
        url: '/token_alive',
        method: 'POST',
        body: { ...params },
      }),
    }),
  })
});


import { useForm as useFormRHF } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldValues, UseFormReturn } from 'react-hook-form/dist/types';
import { UseFormProps as UseFormPropsRHK } from 'react-hook-form/dist/types';
import * as zod from 'zod';

type TUseFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown
> = Omit<UseFormPropsRHK<TFieldValues, TContext>, 'resolver'> & {
  schema?: zod.AnyZodObject;
};

export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown
>(
  props?: TUseFormProps<TFieldValues, TContext>
): UseFormReturn<TFieldValues, TContext> {
  return useFormRHF({
    ...props,
    resolver: props?.schema ? zodResolver(props.schema) : undefined,
  });
}

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `
);

export const ServiceCount = styled.div(
  ({ theme }) => css`
    margin-left: 2px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    padding: 2px 4px;
    background-color: ${theme.palette.primary.lighter};
    color: ${theme.palette.text.tertiary};
  `
);

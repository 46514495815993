import React, {
  FC,
  InputHTMLAttributes,
  useRef,
  useEffect,
  forwardRef,
} from 'react';

import { joinClasses } from 'utils/joinClasses';
import { mergeRefs } from 'utils/mergeRefs';

import { StyledAppCheckbox } from './AppCheckbox.styles';

export interface IAppCheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  isIndeterminate?: boolean;
  size?: 'm' | 'l';
}

const AppCheckbox: FC<IAppCheckboxProps> = forwardRef<
  HTMLInputElement,
  IAppCheckboxProps
>(
  (
    {
      children,
      className,
      isIndeterminate = false,
      disabled,
      title,
      size = 'm',
      ...rest
    },
    ref
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (!checkboxRef || !checkboxRef.current) {
        return;
      }

      checkboxRef.current.indeterminate = isIndeterminate;
    }, [isIndeterminate]);

    return (
      <StyledAppCheckbox
        className={joinClasses('app-checkbox', className)}
        title={title}
        disabled={disabled}
        size={size}
      >
        <input
          type="checkbox"
          className="app-checkbox__checkbox"
          disabled={disabled}
          {...rest}
          ref={mergeRefs([ref, checkboxRef])}
        />

        {children}
      </StyledAppCheckbox>
    );
  }
);

AppCheckbox.displayName = 'AppCheckbox';

export default AppCheckbox;

import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Fade, FadeProps, useTheme } from '@mui/material';

const AppFade: ForwardRefRenderFunction<unknown, FadeProps> = (props, ref) => {
  const { children, ...rest } = props;
  const theme = useTheme();

  return (
    <Fade timeout={theme.transitions.duration.shortest} {...rest} ref={ref}>
      {children}
    </Fade>
  );
};

export default forwardRef(AppFade);

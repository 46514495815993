import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledControlBookingDrawer = styled.section(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;

    .control-booking-drawer__close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: ${theme.palette.text.primary};

      &:hover,
      &:active,
      &:focus {
        color: ${theme.palette.text.primary};
        opacity: 0.5;
      }
    }

    .control-booking-drawer__icon {
      margin-bottom: 20px;
    }

    .control-booking-drawer__title {
      margin-bottom: 16px;
    }

    .control-booking-drawer__text {
      margin-bottom: 40px;
      width: 80%;
      text-wrap: balance;
      text-align: center;
    }

    .control-booking-drawer__control {
      border-radius: 100px;
      padding: 12px 24px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &_cancel {
        background-color: ${theme.palette.error.main};
        border-color: ${theme.palette.error.main};

        &:hover,
        &:active,
        &:focus {
          background-color: ${theme.palette.error.light};
          border-color: ${theme.palette.error.light};
        }

        &:disabled {
          border-color: ${theme.palette.controls.disabled};
        }
      }
    }
  `,
);

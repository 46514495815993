import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { IAppBtnProps } from './AppBtn';

export const StyledAppBtn = styled.button<
  Pick<IAppBtnProps, 'filling' | 'iconHeight' | 'fullWidth' | 'size'>
>(({ theme, filling, iconHeight, fullWidth, size }) => {
  const getFillingStyles = () => {
    switch (filling) {
      case 'primary':
        return css`
          background-color: ${theme.palette.primary.main};
          border: 1px solid ${theme.palette.primary.main};
          color: ${theme.palette.text.invertedPrimary};

          @media (any-hover: hover) {
            &:hover {
              background-color: ${theme.palette.primary.light};
              border: 1px solid ${theme.palette.primary.light};
            }
          }

          &:active {
            background-color: ${theme.palette.primary.dark};
            border: 1px solid ${theme.palette.primary.dark};
          }

          &:disabled {
            color: ${theme.palette.text.disabled};
            background-color: ${theme.palette.controls.disabled};
            border: 1px solid ${theme.palette.controls.disabled};
          }
        `;

      case 'filled':
        return css`
          border-radius: 10px;
          background-color: ${theme.palette.primary.lighter};
          border: 1px solid ${theme.palette.primary.lighter};
          color: ${theme.palette.text.accent};

          @media (any-hover: hover) {
            &:hover {
              background-color: ${theme.palette.controls.selected};
              border: 1px solid ${theme.palette.controls.selected};
            }
          }

          &:active {
            background-color: ${theme.palette.secondary.light};
            border: 1px solid ${theme.palette.secondary.light};
          }

          &:disabled {
            color: ${theme.palette.text.disabled};
            background-color: ${theme.palette.controls.disabled};
            border: 1px solid ${theme.palette.controls.disabled};
          }
        `;

      case 'secondary':
        return css`
          background-color: ${theme.palette.background.neutral};
          border: 1px solid ${theme.palette.background.neutral};
          color: ${theme.palette.primary.main};

          @media (any-hover: hover) {
            &:hover {
              background-color: ${theme.palette.primary.lighter};
              border: 1px solid ${theme.palette.primary.lighter};
            }
          }

          &:active {
            background-color: ${theme.palette.controls.selected};
            border: 1px solid ${theme.palette.controls.selected};
          }

          &:disabled {
            color: ${theme.palette.text.tertiary};
            background-color: ${theme.palette.background.neutral};
            border: 1px solid ${theme.palette.background.neutral};
          }
        `;

      case 'danger':
        return css`
          background-color: ${theme.palette.error.main};
          border: 1px solid ${theme.palette.error.main};
          color: ${theme.palette.text.invertedPrimary};

          @media (any-hover: hover) {
            &:hover {
              background-color: ${theme.palette.error.light};
              border: 1px solid ${theme.palette.error.light};
            }
          }

          &:active {
            background-color: ${theme.palette.error.dark};
            border: 1px solid ${theme.palette.error.dark};
          }

          &:disabled {
            color: ${theme.palette.text.disabled};
            background-color: ${theme.palette.controls.disabled};
            border: 1px solid ${theme.palette.controls.disabled};
          }
        `;

      case 'border':
        return css`
          background-color: transparent;
          border: 1px solid ${theme.palette.primary.main};
          color: ${theme.palette.primary.main};

          @media (any-hover: hover) {
            &:hover {
              background-color: ${theme.palette.primary.lighter};
            }
          }

          &:active {
            background-color: ${theme.palette.controls.selected};
          }

          &:disabled {
            color: ${theme.palette.text.disabled};
            border: 1px solid ${theme.palette.controls.disabled};
          }
        `;

      case 'none':
        return css`
          background-color: transparent;
          border: 1px solid transparent;
          color: ${theme.palette.primary.main};

          @media (any-hover: hover) {
            &:hover {
              background-color: ${theme.palette.primary.lighter};
            }
          }

          &:active {
            background-color: ${theme.palette.controls.selected};
          }

          &:disabled {
            color: ${theme.palette.text.disabled};
          }
        `;

      case 'ascetic':
        return css`
          background-color: transparent;
          border: 1px solid transparent;
          color: ${theme.palette.primary.main};

          @media (any-hover: hover) {
            &&:hover {
              color: ${theme.palette.primary.light};
            }
          }

          &&:active {
            color: ${theme.palette.primary.dark};
          }

          &&:disabled {
            color: ${theme.palette.text.disabled};
          }
        `;

      default:
        return null;
    }
  };

  return css`
    position: relative;
    padding: ${filling === 'ascetic' ? '9px' : '9px 20px'};
    font-size: 16px;
    font-weight: 500;
    border-radius: 3em;
    display: flex;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    transition-property: background-color, border, color, transform;
    transition-timing-function: ease-out;
    transition-duration: ${theme.transitions.duration.shortest}ms;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    ${fullWidth ? 'width: 100%;' : ''}
    ${size === 's' ? 'height: 36px;' : ''}
    ${size === 'm' ? 'height: 48px;' : ''}
    ${size === 'l' ? 'height: 54px;' : ''}

    &:disabled {
      pointer-events: none;
    }

    ${getFillingStyles()}

    .app-btn {
      &__icon {
        width: auto;
        height: ${iconHeight ? `${iconHeight}px` : 'calc(1em * 1.25)'};
        display: block;
        flex-shrink: 0;
      }

      &__icon *[stroke] {
        stroke: currentColor;
      }

      &__icon *[fill] {
        fill: currentColor;
      }

      &__spinner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 3em;
        background-color: ${filling &&
        {
          primary: theme.palette.controls.disabled,
          filled: theme.palette.controls.disabled,
          border: theme.palette.controls.disabled,
          none: theme.palette.background.primary,
          ascetic: theme.palette.background.primary,
          secondary: theme.palette.background.primary,
          danger: theme.palette.controls.disabled,
        }[filling]};
      }
    }
  `;
});

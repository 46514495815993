import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { ITimeSlot } from 'types/types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/ui-kit/AppAccordion';

import { isSlotDisabled } from '../utils';

import { StyledDayPartSlots, Slots, SlotItem } from './DayPartSlots.styles';

interface IDayPartSlotsProps {
  title: string;
  color: string;
  slots: ITimeSlot[];
  selected: ITimeSlot[];
  onSelect: (slots: ITimeSlot[]) => void;
}

export const DayPartSlots = ({
  title,
  color,
  slots,
  selected,
  onSelect,
}: IDayPartSlotsProps) => {
  const { t } = useTranslation();

  const onChange = (slot: ITimeSlot) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onSelect([...selected, slot]);
      } else {
        onSelect([...selected.filter(item => item.from !== slot.from)]);
      }
    };
  };

  return (
    <StyledDayPartSlots>
      <Accordion defaultExpanded>
        <AccordionSummary style={{ backgroundColor: color }}>
          <Typography
            component="h5"
            fontSize={14}
            fontWeight={500}
            color="text.primary">
            {t(title as any)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Slots>
            {slots.map(slot => {
              const disabled = isSlotDisabled(slot);
              const checked =
                selected.findIndex(item => item.from === slot.from) !== -1;

              return (
                <SlotItem key={slot.slot} disabled={disabled} checked={checked}>
                  <input
                    type="checkbox"
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange(slot)}
                  />

                  <Typography
                    component="p"
                    fontSize={14}
                    fontWeight={checked ? 600 : 500}
                    lineHeight="18.2px"
                    color={
                      checked
                        ? 'text.accent'
                        : disabled
                          ? 'text.disabled'
                          : 'text.secondary'
                    }>
                    {slot.slot}
                  </Typography>
                </SlotItem>
              );
            })}
          </Slots>
        </AccordionDetails>
      </Accordion>
    </StyledDayPartSlots>
  );
};

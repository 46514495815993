import React from 'react';
import styled from '@emotion/styled';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const AppTextField = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  '& label': {
    color: theme.palette.text.tertiary,
  },
  '& label.Mui-focused': {
    color: theme.palette.text.tertiary,
  },
  '& label:not(.MuiInputLabel-shrink)': {
    fontSize: '14px',
    color: theme.palette.text.tertiary,
    transform: 'translate(14px, 13px) scale(1)',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiInputBase-root': {
    padding: '12.5px 14px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',

    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.background.line,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.background.line,
    },
    '&.Mui-focused fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '14px',
    color: theme.palette.text.primary,
  },
}));

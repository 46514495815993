import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routers/constants';

import { privateApi } from 'store/api';

import { isApiError, isFetchBaseQueryError } from 'utils/network';
import { ICancelOrder } from 'types/admin';
import { AppNoFillingControlBtn } from 'components/ui-kit/AppNoFillingControlBtn';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';
import { useSafeParams } from 'hooks/useSafeParams';
import { assertIsDefined } from 'utils/assertion';

import { CrossIcon, StatusCancel, StatusCheck } from 'assets/images';

import { StyledRoot } from './CancelOrderDrawer.styles';

type TCancelOrderDrawerProps = {
  isOrderCanceled?: boolean;
  rentalOrderId: string;
  cancelResult: ICancelOrder | null;
  onCanceled: (result: ICancelOrder) => void;
  onClose: () => void;
};

export const CancelOrderDrawer = (props: TCancelOrderDrawerProps) => {
  const { isOrderCanceled, rentalOrderId, cancelResult, onCanceled, onClose } =
    props;
  const navigate = useNavigate();
  const { publicId } = useSafeParams();

  const { t } = useTranslation();

  const [cancelOrder, { isLoading }] = privateApi.useCancelOrderMutation();

  const onDeleteVisit = async () => {
    try {
      assertIsDefined(publicId);

      const result = await cancelOrder({
        public_id: publicId,
        rental_order_id: Number(rentalOrderId),
      }).unwrap();

      onCanceled(result.data);
    } catch (e) {
      if (isFetchBaseQueryError(e) && isApiError(e.data)) {
        toast.error(e.data.error?.message, {
          position: 'bottom-center',
        });
      }
    }
  };

  const onRebook = () => {
    navigate(`/${publicId}/${Paths.Admin}`);
  };

  const isSuccess = cancelResult?.result.info.result === 'success';

  if (isOrderCanceled) {
    return (
      <StyledRoot>
        <div className="cancel-order-drawer__icon">
          {isSuccess ? <StatusCheck /> : <StatusCancel />}
        </div>

        <Typography
          className="cancel-order-drawer__title"
          component="h2"
          color="text.secondary"
          fontSize={16}
          lineHeight={1.3}
          fontWeight={600}>
          {cancelResult?.result.info.info}
        </Typography>

        {isSuccess && (
          <Typography
            className="cancel-order-drawer__text"
            component="p"
            fontSize={14}
            lineHeight={1.3}
            fontWeight={400}
            marginBottom="40px">
            {t('AGAIN_TEXT')}
          </Typography>
        )}

        <AppBtn
          className="cancel-order-drawer__control"
          onClick={onRebook}
          fullWidth>
          {t('EDIT_REBOOK')}
        </AppBtn>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot>
      <AppBtn
        className="cancel-order-drawer__close"
        filling="ascetic"
        onClick={onClose}
        LeftIcon={CrossIcon}
      />
      <div className="cancel-order-drawer__icon">
        <StatusCancel />
      </div>

      <Typography
        className="cancel-order-drawer__title"
        component="h2"
        color="text.secondary"
        fontSize={16}
        lineHeight={1.3}
        fontWeight={600}>
        {t('EDIT_CANCEL_TITLE')}
      </Typography>

      <Typography
        className="cancel-order-drawer__text"
        component="p"
        marginBottom="16px"
        fontSize={14}
        lineHeight={1.3}
        fontWeight={400}>
        {t('EDIT_SURE_CANCEL_1_TEXT')}
      </Typography>

      <Typography
        className="cancel-order-drawer__text"
        component="p"
        marginBottom="40px"
        fontSize={14}
        lineHeight={1.3}
        fontWeight={400}>
        {t('EDIT_SURE_CANCEL_2_TEXT')}
      </Typography>

      <AppBtn
        className="cancel-order-drawer__control"
        onClick={onDeleteVisit}
        filling="danger"
        fullWidth
        isLoading={isLoading}>
        {t('EDIT_CANCEL_BOOKING')}
      </AppBtn>

      <AppNoFillingControlBtn
        className="cancel-order-drawer__control"
        onClick={onClose}
        fullWidth>
        {t('CLOSE')}
      </AppNoFillingControlBtn>
    </StyledRoot>
  );
};

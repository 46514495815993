import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    .payment-type-form__wrapper {
      display: none;
    }
  `
);

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { joinClasses } from 'utils/joinClasses';
import { formatCurrency } from 'utils/formatCurrency';

import { StyledAppAmount } from './AppAmount.styles';

export interface IAppAmountProps {
  amount: number;
  currency?: string | null;
  lang?: string;
  className?: string;
}

const AppAmount: FC<IAppAmountProps> = ({ amount, className }) => {
  const { i18n } = useTranslation();
  const currencyToUse = 'RUB'; // Пока что нет конвертации RUB => USD i18n.language === 'en' ? 'USD' : 'RUB';
  const formattedAmount = formatCurrency(amount, currencyToUse, 'ru');

  return (
    <StyledAppAmount className={joinClasses('app-amount', className)}>
      {formattedAmount}
    </StyledAppAmount>
  );
};

export default AppAmount;

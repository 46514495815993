import { css, useTheme } from '@emotion/react';

import { useScrollbarStyles } from 'hooks/useScrollbarStyles';

export const useAppSelectStyles = () => {
  const theme = useTheme();

  return css`
    &__control {
      cursor: text;
      padding: 15px;
      border-radius: 10px;
      border: 1px solid ${theme.palette.background.line};
      column-gap: 10px;

      &--is-focused {
        box-shadow: none;
        border: 1px solid ${theme.palette.primary.main};
      }

      &--menu-is-open {
        [class*='__dropdown-indicator'] {
          transform: rotateX(180deg);
        }
      }

      @media (any-hover: hover) {
        &:not(&--is-focused):hover {
          border: 1px solid ${theme.palette.background.line};
        }

        &--is-focused:hover {
          border: 1px solid ${theme.palette.primary.main};
        }

        &:hover {
          [class*='__dropdown-indicator'] {
            background-color: ${theme.palette.primary.lighter};
          }
        }
      }
    }

    &__value-container {
      padding: 0;
      gap: 5px;
    }

    &__input-container {
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 1.3;
      color: ${theme.palette.text.primary};
    }

    &__placeholder {
      font-size: 14px;
      line-height: 1.3;
      color: ${theme.palette.text.tertiary};
    }

    &__indicators {
      column-gap: 5px;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      cursor: pointer;
      border-radius: 50%;
      padding: 0;
      transition-property: transform, background-color;
      transition-timing-function: ease-out;
      transition-duration: ${theme.transitions.duration.shortest}ms;

      & > svg {
        height: 20px;
        width: auto;

        & *[stroke] {
          stroke: ${theme.palette.primary.main};
        }

        & *[fill] {
          fill: ${theme.palette.primary.main};
        }
      }

      @media (any-hover: hover) {
        &:hover {
          background-color: ${theme.palette.primary.lighter};
        }
      }

      &:active {
        background-color: ${theme.palette.controls.selected};
      }
    }

    &__multi-value {
      border-radius: 3em;
      background-color: transparent;
      border: 1px solid ${theme.palette.background.line};
      align-items: center;
      column-gap: 5px;
      margin: 0;
      padding: 2px 4px 2px 8px;
      cursor: default;

      &__label {
        font-size: 14px;
        line-height: 1.3;
        padding: 0;
      }

      &__remove {
        padding: 5px;
        cursor: pointer;
        border-radius: 50%;
        -webkit-tap-highlight-color: transparent;
        transition: background-color ${theme.transitions.duration.shortest}ms
          ease-out;

        & > svg {
          height: 14px;
          width: auto;
        }

        @media (any-hover: hover) {
          &:hover {
            background-color: ${theme.palette.primary.lighter};
          }
        }

        &:active {
          background-color: ${theme.palette.controls.selected};
        }
      }
    }

    &__menu {
      margin-bottom: 5px;
      margin-top: 5px;
      box-shadow: ${theme.shadows[1]};
      border-radius: 6px;
      overflow: hidden;
      background-color: ${theme.palette.background.primary};
      z-index: 100;
    }

    &__menu-list {
      padding: 0;
      max-height: 170px;
      ${useScrollbarStyles()}
    }

    &__option,
    &__single-value {
      font-size: 14px;
      line-height: 1.3;
      color: ${theme.palette.text.secondary};

      &--is-focused {
        cursor: pointer;
        background-color: ${theme.palette.primary.lighter};
      }
      &--is-selected {
        cursor: pointer;
        background-color: ${theme.palette.controls.selected};
      }

      &:active {
        background-color: ${theme.palette.controls.selected};
      }
    }

    &__option {
      &:first-of-type {
        border-radius: 6px 6px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 6px 6px;
      }
    }

    &__menu-notice--no-options {
      font-size: 14px;
      line-height: 1.3;
    }

    &__control--is-disabled {
      border-color: ${theme.palette.background.line};
      background-color: ${theme.palette.background.neutral};
      .app-creatable-select__value-container,
      .app-creatable-select__placeholder {
        color: ${theme.palette.text.disabled};
      }

      & svg *[stroke] {
        stroke: ${theme.palette.text.disabled};
      }

      & svg *[fill] {
        fill: ${theme.palette.text.disabled};
      }
    }
  `;
};

import React, { FC, InputHTMLAttributes, forwardRef, useId } from 'react';

import { joinClasses } from 'utils/joinClasses';

import { StyledAppSwitch } from './AppSwitch.styles';

export interface IAppSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  height?: number;
  reversed?: boolean;
}

const AppSwitch: FC<IAppSwitchProps> = forwardRef<
  HTMLInputElement,
  IAppSwitchProps
>(({ className, children, height = 20, reversed, ...rest }, ref) => {
  const id = useId();

  return (
    <StyledAppSwitch
      className={joinClasses('app-switch', className)}
      height={height}
      reversed={reversed}
    >
      <input
        type="checkbox"
        className="app-switch__switcher"
        id={id}
        {...rest}
        ref={ref}
      />

      {children && (
        <label htmlFor={id} className="app-switch__label">
          {children}
        </label>
      )}
    </StyledAppSwitch>
  );
});

AppSwitch.displayName = 'AppSwitch';

export default AppSwitch;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.section(
  () => css`
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
);

export const PriceInfo = styled.div(
  () => css`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  `,
);

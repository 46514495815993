import React from 'react';
import { Typography } from '@mui/material';

import { joinClasses } from 'utils/joinClasses';

import { StyledPaymentStatus } from './PaymentStatus.style.tsx';

interface IPaymentStatusProps {
  className?: string;
  status: 'paid' | 'no-paid';
}

export const PaymentStatus = (props: IPaymentStatusProps) => {
  const { status } = props;

  const statusText = (status: string) => {
    switch (status) {
    case 'paid':
      return 'Оплачено';
    case 'no-paid':
      return 'Не оплачено';
    }
  };

  return (
    <StyledPaymentStatus className={joinClasses('payment-status')}>
      <Typography
        component="span"
        fontSize={14}
        lineHeight={1.3}
        className={joinClasses('payment-status', `payment-status__text payment-status--${status}`)}
      >
        {statusText(status)}
      </Typography>
    </StyledPaymentStatus>
  );
};

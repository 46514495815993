import { css } from '@emotion/react';
import styled from '@emotion/styled';

import AppBtn from 'components/ui-kit/AppBtn/AppBtn';

export const Root = styled.form(
  () => css`
    flex: 1;
    display: flex;
    flex-direction: column;

    .edit-book__spinner {
      margin-bottom: 20px;
    }

    .edit-book__result-price {
      margin-top: 16px;
      margin-bottom: 20px;
    }
  `
);

export const RootHeader = styled.header(
  () => css`
    text-align: center;
    padding: 20px 0;
  `
);

export const Statuses = styled.header(
  () => css`
    padding: 0 0 20px 0;
  `
);

export const RootContent = styled.section(
  ({ theme }) => css`
    padding: 0 16px 10px;

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      padding: 0 20px 40px;
    }
  `
);

export const ServiceItem = styled.section(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-bottom: 1px solid ${theme.palette.background.line};
  `
);

export const AddSetBtn = styled(AppBtn)(
  ({ theme }) => css`
    margin-top: 16px;
  `
);

export const CenterContainer = styled.section(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

export const StyledDeleteButton = styled(AppBtn)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    margin-top: 20px;
  `
);

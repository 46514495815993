import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledClientInformation = styled.form(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;

    .client-form {
      padding: 20px 16px 40px;

      @media (min-width: ${theme.breakpoints.values.sm}px) {
        padding: 20px 20px 40px;
      }
    }
  `
);

import React, { FC } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import { DropDownIcon } from 'assets/images';

export const DropdownIndicator: FC<DropdownIndicatorProps> = (props) => (
  <components.DropdownIndicator {...props}>
    <DropDownIcon />
  </components.DropdownIndicator>
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.section(
  () => css`
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
);

export const Main = styled.div(
  ({ theme }) => css`
    padding: 30px 16px;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      padding: 30px 20px;
    }
  `,
);

export const ServiceItem = styled.div(
  ({ theme }) => css`
    padding-bottom: 24px;
    border-bottom: 1px solid ${theme.palette.background.line};

    & + & {
      margin-top: 30px;
    }

    .service-settings {
      margin-top: 14px;
    }
  `,
);

import {normalizeAmount} from './normalizeAmount';

export function formatCurrency(amount: number, currency: string | null, lang: string): string {
  if (!currency) {
    return normalizeAmount(amount).toLocaleString(lang, { style: 'decimal' });
  }

  const currencyCode = currency.toUpperCase();
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  try {
    return normalizeAmount(amount).toLocaleString(lang, options);
  } catch (e) {
    return amount.toString();
  }
}

import React from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/query';

import { publicApi } from 'store/api';
import { getOrderItems } from 'store/slices/order';

import { useDateLocale } from 'i18n/useDateLocale';
import { IAdditionalServiceOrder, IRentalItem, ITimeSlot } from 'types/types';
import { useSafeParams } from 'hooks/useSafeParams';
import AppAmount from 'components/ui-kit/AppAmount/AppAmount';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { parseApiDate } from 'utils/parseApiDate';

import { OrderTimeDetails } from '../OrderTimeDetails';

import { StyledOrderDetails, Times } from './OrderDetails.styles';

interface IOrderDetailsProps {
  services: IRentalItem[];
  slots: ITimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
}

export const OrderDetails = ({
  services,
  slots,
  additionalServiceOrders,
}: IOrderDetailsProps) => {
  const locale = useDateLocale();

  const { publicId } = useSafeParams();

  const {
    data: totalSetPrice,
    isFetching: priceFetching,
    isError: priceError,
  } = publicApi.useGetTotalSetPriceQuery(
    publicId && slots.length > 0 && services.length > 0
      ? {
          public_id: publicId,
          orders: getOrderItems({
            services,
            slots,
            additionalServiceOrders,
          }),
        }
      : skipToken
  );

  const canShowPrice =
    slots.length > 0 && services.length > 0 && totalSetPrice && !priceError;

  const date = slots.length > 0 ? parseApiDate(slots[0].from) : new Date();

  return (
    <StyledOrderDetails>
      <Times>
        {slots.length > 0 ? (
          <Typography fontSize={14} color="text.secondary">
            {format(date, 'd MMMM, EEEEEE', {
              locale,
            })}
          </Typography>
        ) : null}
        <OrderTimeDetails
          services={services}
          slots={slots}
          additionalServiceOrders={additionalServiceOrders}
        />
      </Times>
      {priceFetching ? (
        <span>
          <AppSpinner size={20} />
        </span>
      ) : canShowPrice ? (
        <Typography fontSize={20} fontWeight={600}>
          <AppAmount amount={totalSetPrice.data.result.total_set_price} />
        </Typography>
      ) : null}
    </StyledOrderDetails>
  );
};

import React, {
  InputHTMLAttributes,
  useRef,
  useState,
  FocusEvent,
  forwardRef,
  useImperativeHandle,
  useId,
  ReactElement,
  useEffect,
  KeyboardEvent,
  ForwardRefRenderFunction,
} from 'react';

import { joinClasses } from 'utils/joinClasses';
import { checkIsMacOs } from 'utils/checkIsMacOs';
import { INPUT_MAX_LENGTH } from 'constants/mask';
import AppCrossBtn from 'components/ui-kit/AppCrossBtn/AppCrossBtn';

import { StyledAppInput } from './AppInput.styles';

export interface IAppInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isSelectOnFocus?: boolean;
  label?: string;
  errCssMinHeight?: string;
  errText?: string;
  isValid?: boolean;
  iconSize?: number;
  visibilityPasswordAriaLabel?: string;
  cleaningBtnAriaLabel?: string;
  leftEl?: ReactElement | string;
  rightEl?: ReactElement | string;
  onClear?: () => void;
}

const getInputType = (type: string, isPassVisible: boolean) => {
  if (type !== 'password' && type !== 'text') {
    return type;
  }

  if (type !== 'password' || (type === 'password' && isPassVisible)) {
    return 'text';
  }

  return 'password';
};

const AppInput: ForwardRefRenderFunction<HTMLInputElement, IAppInputProps> = (
  {
    className,
    errCssMinHeight,
    type,
    label,
    placeholder = ' ',
    leftEl,
    rightEl,
    errText,
    isValid = true,
    iconSize = 24,
    value,
    disabled,
    isSelectOnFocus = true,
    visibilityPasswordAriaLabel = 'Показать или скрыть пароль.',
    cleaningBtnAriaLabel = 'Очистить поле ввода.',
    maxLength = INPUT_MAX_LENGTH,
    required,
    onClear,
    onFocus,
    onBlur,
    onKeyDown,
    ...rest
  },
  ref,
) => {
  const id = useId();

  const previousValue = useRef<IAppInputProps['value']>(value);

  const [isLabelRaised, setIsLabelRaised] = useState(false);

  const [isPassVisible, setIsPassVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => inputRef.current,
  );

  useEffect(() => {
    value && setIsLabelRaised(true);
  }, [value]);

  const handleClearBtnClick = () => {
    if (onClear) {
      previousValue.current = value;
      onClear();
      inputRef.current?.focus();
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (isSelectOnFocus) {
      e.target.select();
    }

    setIsLabelRaised(true);

    onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!value) {
      setIsLabelRaised(false);
    }

    onBlur?.(e);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const isCtrlKeyDown = (!checkIsMacOs() && e.ctrlKey) || e.metaKey;

    if (e.key === 'z' && isCtrlKeyDown) {
      e.preventDefault();

      if (inputRef.current) {
        const nativeInputValue = Object.getOwnPropertyDescriptor?.(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;

        nativeInputValue?.call(inputRef.current, previousValue.current);
        inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      }
    }

    onKeyDown?.(e);
  };

  const isClearBtnVisible = value && onClear;

  return (
    <StyledAppInput
      className={joinClasses('app-input', className)}
      errCssMinHeight={errCssMinHeight}
      isPassVisible={isPassVisible}
      isLabelRaised={isLabelRaised}
      type={type}
      isValid={isValid}
      errText={errText}
      iconSize={iconSize}
      disabled={disabled}>
      <div className="app-input__input-wrapper">
        <label className="app-input__input-box">
          <div className="app-input__left-el">{leftEl}</div>

          <input
            className="app-input__input"
            type={getInputType(type || 'text', isPassVisible)}
            value={value}
            ref={inputRef}
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            id={id}
            placeholder={placeholder}
            maxLength={maxLength}
            onKeyDown={handleKeyDown}
            {...rest}
          />

          {label ? (
            <label htmlFor={id} className="app-input__label">
              {required ? label + ' *' : label}
            </label>
          ) : null}

          <div className="app-input__right-el">
            {isClearBtnVisible && (
              <AppCrossBtn
                className="app-input__clear-btn"
                disabled={disabled}
                iconHeight={iconSize}
                aria-label={cleaningBtnAriaLabel}
                onClick={handleClearBtnClick}
              />
            )}
            {rightEl}
          </div>
        </label>
      </div>

      <p className="app-input__err">{errText}</p>
    </StyledAppInput>
  );
};
export default forwardRef(AppInput);

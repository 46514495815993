import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledResultPrice = styled.footer(
  ({ theme }) => css`

    .result-price_success {
      color: ${theme.palette.success.main};

      .result-price__amount {
        color: ${theme.palette.success.main};
      }
    }

    .result-price_pending {
      color: ${theme.palette.text.secondary};
    }

    .result-price__wrapper {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
    }

    .result-price__dotted {
      align-self: end;
      margin-bottom: 2px;
      border-bottom: 2px dotted ${theme.palette.background.line};
    }

    .result-price__amount {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.3;
      color: ${theme.palette.text.secondary};
    }

    .result-price__amount_status {
      font-size: 16px;
    }

    .result-price__holder {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 4px;
      color: inherit;
    }
  `
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div(
  () => css`
    flex: 1;
    display: flex;
    flex-direction: column;
  `
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import AppBtn from 'components/ui-kit/AppBtn/AppBtn';

export const StyledAppControlBtn = styled(AppBtn)(
  ({ theme }) => css`
    background-color: ${theme.palette.controls.selected};
    padding: 6px 9px;
    color: ${theme.palette.text.secondary};
    font-size: 14px;
    column-gap: 10px;
    border-radius: 8px;

    .app-btn__icon {
      height: 20px;
      color: ${theme.palette.primary.main};
    }
  `
);

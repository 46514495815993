import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Popover, Typography } from '@mui/material';

import { IResultStepOrderItem } from 'features/resultStep/types';
import AppInput from 'components/ui-kit/AppInput/AppInput';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';
import ShareSocial from 'components/ShareSocial/ShareSocial';
import copyToClipboard from 'utils/copyToClipboard';
import { getMessageSocial } from 'features/resultStep/utils';
import { AppQrModal } from 'components/app-modals/index.ts';
import { convertUrlToQrImgSrc } from 'utils/qr';

import { CopyIcon, QrIcon, ShareSocialIcon } from 'assets/images';

import {
  CopyActions,
  CopyInput,
  PopupShareSocial,
} from './SharedLinks.style.tsx';


interface ISharedLinksProps {
  paymentPageUrl: string;
  orders: IResultStepOrderItem[];
}

export const SharedLinks = ({
  paymentPageUrl,
  orders,
}: ISharedLinksProps) => {
  const [openShareEl, setOpenShareEl] = useState<HTMLButtonElement | null>(
    null
  );

  const [qrForModal, setQrForModal] = useState<boolean>(false);
  const qrCodeImage = convertUrlToQrImgSrc(paymentPageUrl);
  
  const messageSocial = getMessageSocial(orders);

  const onCopy = (url: string) => {
    copyToClipboard(url, () => {
      toast.success('Ссылка скопирована');
    });
  };

  return (
    <>
      <Typography fontSize={14} fontWeight={600} color="text.secondary">
        Ссылка на оплату
      </Typography>
      <CopyInput>
        <AppInput readOnly value={paymentPageUrl} />
        <CopyActions>
          <AppBtn
            LeftIcon={CopyIcon}
            onClick={() => onCopy(paymentPageUrl)}
            size="s"
          />
          <AppBtn
            className="payment__share-social"
            filling="secondary"
            LeftIcon={QrIcon}
            onClick={() => setQrForModal(true)}
            size="s"
          />
          <AppBtn
            className="payment__share-social"
            filling="secondary"
            LeftIcon={ShareSocialIcon}
            onClick={(e) => setOpenShareEl(e.currentTarget)}
            size="s"
          />

          {qrForModal && <AppQrModal
            open={Boolean(qrCodeImage)}
            imgSrc={qrCodeImage || ''}
            onClose={() => setQrForModal(false)}
          />}

          <Popover
            open={Boolean(openShareEl)}
            anchorEl={openShareEl}
            onClose={() => setOpenShareEl(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <PopupShareSocial>
              <ShareSocial
                url={paymentPageUrl}
                text={messageSocial}
                socials={['tg', 'vk', 'viber', 'whatsApp']}
              />
            </PopupShareSocial>
          </Popover>
        </CopyActions>
      </CopyInput>
    </>
  );
};

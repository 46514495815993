import React, { FC } from 'react';

import { joinClasses } from 'utils/joinClasses';
import { IAppBtnProps } from 'components/ui-kit/AppBtn/AppBtn';

import { CrossIcon } from 'assets/images';

import { StyledAppCrossBtn } from './AppCrossBtn.styles';

interface IAppCrossBtnProps extends IAppBtnProps {}

const AppCrossBtn: FC<IAppCrossBtnProps> = ({ className, ...rest }) => {
  return (
    <StyledAppCrossBtn
      filling="none"
      className={joinClasses('close-btn', className)}
      RightIcon={CrossIcon}
      type="button"
      {...rest}
    />
  );
};

export default AppCrossBtn;

import i18n from 'i18next';
import { z } from 'zod';

import { SelectOptionSchema } from 'components/app-selects';

export const ClientInformationInAdminSchema = z.object({
  first_name: z
    .string({ required_error: i18n.t('REQUIRED_ERROR_MESSAGE') })
    .min(1, i18n.t('REQUIRED_ERROR_MESSAGE'))
    .max(40, i18n.t('MAX_LENGTH_NAME_ERROR_MESSAGE')),
  last_name: z.string().optional(),
  email: z
    .string({ required_error: i18n.t('REQUIRED_ERROR_MESSAGE') })
    .max(50, i18n.t('MAX_LENGTH_EMAIL_ERROR_MESSAGE'))
    .email(i18n.t('INCORRECT_EMAIL'))
    .or(z.string().length(0).optional())
    .transform((v) => (v === '' ? undefined : v)),
  phone: z
    .string()
    .optional()
    .refine(
      (phone) => {
        if (phone === undefined || phone === '') {
          return true;
        }
        const trimmedPhone = phone.replace(/\D/g, '');
        if (trimmedPhone[0] === '7') {
          return trimmedPhone.length === 11;
        } else {
          return trimmedPhone.length >= 11;
        }
      },
      { message: i18n.t('MIN_LENGTH_PHONE_RU_ERROR_MESSAGE') }
    ),
  payment_type: SelectOptionSchema,
  comment: z.string().max(325).optional(),
  remind: z.boolean().optional(),
  remindTime: SelectOptionSchema.optional(),
  notification: z
    .object({
      sms: z.boolean().optional(),
      email: z.boolean().optional(),
    })
    .optional(),
});

export type TClientInformationInAdmin = z.infer<
  typeof ClientInformationInAdminSchema
>;

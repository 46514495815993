import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledForm = styled.div(
  ({ theme }) => css`
    display: grid;
    flex-direction: column;

    .app-select__form-small .app-select__control {
      padding: 8px;
      background-color: ${theme.palette.background.neutral};
    }

    .app-select__payment-method .app-select__menu {
      top: 35px;
      z-index: 101;
    }

    .help-text {
      display: inline-block;
      position: relative;
      bottom: 6px;
    }

    .form__field {
      margin-top: 9px;
    }
  `
);

export const Remind = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.palette.background.line};
  `
);

export const RemindTime = styled.div(
  () => css`
    display: grid;
    gap: 9px;
    align-items: center;
    grid-auto-flow: column;
  `
);

export const NotificationFields = styled.div(
  () => css`
    padding-top: 10px;
    display: grid;
    gap: 20px;
  `
);

export const OrderInfo = styled.div(
  ({ theme }) => css`
    padding-top: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.palette.background.line};

    .order-info__time-details {
      margin-top: 10px;
    }
  `
);

export const ResultPriceStyled = styled.div(
  () => css`
    padding-top: 16px;
    padding-bottom: 16px;
  `
);

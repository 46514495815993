import React, { FC } from 'react';

import { IAppBtnProps } from 'components/ui-kit/AppBtn/AppBtn';

import { StyledAppControlBtn } from './AppControlBtn.styles';

const AppControlBtn: FC<IAppBtnProps> = ({ children, ...rest }) => {
  return (
    <StyledAppControlBtn filling="none" {...rest}>
      {children}
    </StyledAppControlBtn>
  );
};

export default AppControlBtn;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledResultStatus = styled.section(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    gap: 14px;

    &.result-status_pending {
      .result-status__text {
        width: min(90%, 325px);
        padding: 10px;
        border-radius: 10px;
        text-wrap: wrap;
        color: ${theme.palette.background.default};
        background-color: ${theme.palette.error.light};
      }
    }

    .result-status__title,
    .result-status__text {
      text-align: center;
      margin: 0;
      text-wrap: balance;
    }

    .result-status__text:empty {
      display: none;
    }
  `,
);

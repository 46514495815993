export function assertIsDefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`${value} is not defined`);
  }
}

export function assert(condition: unknown, msg?: string): asserts condition {
  if (condition === false) {
    throw new Error(msg);
  }
}

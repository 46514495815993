import React, { ReactNode } from 'react';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ArrowLeftBoldIcon } from 'assets/images';
import { RuFlagIcon } from 'assets/images/flags';

import { StyledHeader } from './Header.styles';

interface IHeaderProps {
  onBackClick?: () => void;
  children?: ReactNode;
  action?: ReactNode;
}

export const Header = ({ onBackClick, children, action }: IHeaderProps) => {
  const { i18n } = useTranslation();

  return (
    <StyledHeader className="header">
      {onBackClick ? (
        <IconButton
          className="header__back"
          onClick={onBackClick}
          aria-label="back"
        >
          <ArrowLeftBoldIcon />
        </IconButton>
      ) : null}

      {children}

      <div className="header__action">
        {action ? (
          action
        ) : (
          <div className="header__lang">
            <IconButton aria-label="language" className="header__icon">
              {i18n.language === 'ru' && <RuFlagIcon width={26} />}
            </IconButton>
          </div>
        )}
      </div>
    </StyledHeader>
  );
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CopyInput = styled.div(
  () => css`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 14px;
    align-items: center;
  `
);

export const CopyActions = styled.div(
  () => css`
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    align-items: center;

    .payment__share-social {
      width: 36px;
    }
  `
);

export const PopupShareSocial = styled.div(
  () => css`
    padding: 8px;
  `
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledResultStep = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    .result-step__spinner {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .result-step__container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      padding: 20px 16px;
    }

    .result-step__box {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      padding: 20px 12px;
    }

    .result-step__control {
      margin: 0 auto;
      align-items: center;
      border: none;

      svg {
        transform: scale(1);
        transition: scale 0.4s ease;
      }

      &:active {
      }

      &_active {
        svg {
          transform: scale(-1);
          transition: scale 0.4s ease;
        }
      }
    }

    .result-step__social-title {
      text-align: center;
      margin-bottom: 15px;
    }

    .result-status__payment-copy {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 14px;
      align-items: center;
    }
  `,
);

import { IPhoneMaskData } from 'types/general';

export const INPUT_MAX_LENGTH = 128;
export const PHONE_MIN_LENGTH = 11;
export const PHONE_MAX_LENGTH = 15;
export const PHONE_WITHOUT_CODE_LENGTH = 10;
export const PAN_MIN_LENGTH = 16;
export const PAN_MAX_LENGTH = 19;

export const PHONE_WITHOUT_PLUS_MASKS_DATA: IPhoneMaskData[] = [
  { country: 'ru', code: '7', mask: '{7} (000) 000-00-00' },
  { country: 'kz', code: '7', mask: '{7} (000) 000-00-00' },
  { country: 'by', code: '375', mask: '{375} 00 000-00-00' },
];

export const PHONE_MASKS_DATA = PHONE_WITHOUT_PLUS_MASKS_DATA.map((data) => ({
  ...data,
  mask: data.mask.replace(/^\{/, '{+'),
}));

export const PHONE_WITHOUT_CODE_MASK = '000 000-00-00';

export const INPUT_ICON_SIZE = 22;

import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledFirstStep = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    
    @supports (height: 100dvh) {
      height: 100dvh
    }
  `
);

export const ProjectDescription = styled.div(
  () => css`
    margin-top: 30px;
    text-align: center;
  `
);

export const Menu = styled.section(
  ({ theme }) => css`
    padding: 40px 16px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;

    .menu__element {
      position: relative;
      display: flex;
      align-items: center;
      background-color: ${theme.palette.background.neutral};
      border-radius: 10px;
      padding: 30px;
      text-decoration: none;
      transition: 0.4s ease;

      .menu__arrow {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 16px;

        content: "";
        width: 24px;
        height: 24px;

        color: ${theme.palette.primary.main};
      }

      &:hover {
        background-color: ${theme.palette.controls.selected};
        transition: 0.4s ease;
      }
    }
  `
);

export const Brand = styled.footer(
  () => css`
    position: relative;
    margin-top: auto;

    svg {
      width: 100%;
      height: auto;
    }
  `
);

const swimming = keyframes`
  0%,
  100% {
    transform: translateY(60px) rotate(12.6deg);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateY(40px) rotate(3.6deg);
  }
  30% {
    transform: translateY(60px) rotate(12.6deg);
  }
  45% {
    transform: translateY(40px) rotate(3.6deg);
  }
  60% {
    transform: translateY(60px) rotate(12.6deg);
  }
  75% {
    transform: translateY(40px) rotate(3.6deg);
  }
`;

export const BrandImg = styled.figure(
  () => css`
    position: absolute;
    top: -60px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    img {
      max-width: 80%;
      max-height: 100%;
      transform: rotate(3.638deg);
    }
  `
);

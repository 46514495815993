import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { IAppModalTitleProps } from './AppModalTitle';

export const StyledAppModalTitle = styled.h2<
  Pick<IAppModalTitleProps, 'variant'>
>(({ theme, variant }) => {
  const iconBackgroundColor =
    variant &&
    {
      success: theme.palette.success.main,
      delete: theme.palette.error.light,
      err: theme.palette.error.light,
      fail: theme.palette.error.light,
      exit: theme.palette.error.light,
    }[variant];

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    font-weight: 600;
    font-size: 20px;
    color: ${theme.palette.text.primary};
    text-align: center;
    text-wrap: balance;
    margin-bottom: 20px;

    .app-modal-title {
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 8px;
        background-color: ${iconBackgroundColor};

        & *[fill] {
          fill: #fff;
        }

        & *[stroke] {
          stroke: #fff;
        }
      }
    }
  `;
});

import React, { useEffect } from 'react';
import { t } from 'i18next';
import { Typography } from '@mui/material';

import AppLink from 'components/ui-kit/AppLink/AppLink';
import { usePhone } from 'hooks/usePhone';

import { PhoneCallIcon, WhatsAppIcon } from 'assets/images';

import { StyledFeedback } from './Feedback.styles';

type TFeedback = {
  phoneFeedback?: string;
};

export const Feedback = ({ phoneFeedback }: TFeedback) => {
  const { phone, setPhone } = usePhone();

  useEffect(() => {
    if (!phoneFeedback || phoneFeedback.length === 0) {
      return;
    }

    setPhone(phoneFeedback);
  }, [phoneFeedback]);

  const { whatsAppLink, callLink } = phone;

  return (
    <StyledFeedback className="feedback">
      <h2 className="visually-hidden">Обратная связь</h2>
      <Typography
        className="feedback__text"
        component="p"
        width="230px"
        textAlign="center"
        fontSize={14}
        fontWeight={400}
        color="text.primary">
        {t('FEEDBACK')}
      </Typography>
      <div className="feedback__wrapper">
        <AppLink
          to={whatsAppLink}
          target="_blank"
          LeftIcon={() => <WhatsAppIcon color="#0DC143" />}
          className="feedback__link">
          {t('WRITE')}
        </AppLink>
        <AppLink
          to={callLink}
          target="_blank"
          LeftIcon={() => <PhoneCallIcon color="text.accent" />}
          className="feedback__link feedback__link_phone">
          {t('CALL')}
        </AppLink>
      </div>
    </StyledFeedback>
  );
};
